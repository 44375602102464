import { Card, Col, Form, InputNumber, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { settingEdit, SettingList } from "../../redux/admin/setting/action";
import styles from "./admin-worksSpace/course/style.module.css";

const Setting = () => {
    const { Title } = Typography;
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(SettingList()).then((res) => {
            if (res) {
                form.setFieldsValue({
                    videoWatchLimit: res.videoWatchLimit || 0,
                    ...res.coefficients_variables,
                    ...res.performance_weighting,
                });
            }
        });
    }, [dispatch, form]);

    const handleSave = () => {
        form.validateFields()
            .then(async (values) => {
                const payload = {
                    videoWatchLimit: values.videoWatchLimit,
                    coefficients_variables: {
                        k1: values.k1,
                        k2: values.k2,
                        k3: values.k3,
                        k4: values.k4,
                        k5: values.k5,
                    },
                    performance_weighting: {
                        w1: values.w1,
                        w2: values.w2,
                        w3: values.w3,
                        w4: values.w4,
                    },
                };
                const success = await dispatch(settingEdit(payload));
                // if (success) {
                //     message.success("Settings updated successfully!");
                // }
            })
            .catch((errorInfo) => {
                console.error("Validation Failed:", errorInfo);
            });
    };

    return (
        <>
            <div className={styles.headerComponent}>
                <span>{t("Settings")}</span>
                <hr />
            </div>
            <Card>
                <Form form={form} layout="vertical">
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Video Watch Count"
                                name="videoWatchLimit"
                                rules={[
                                    { required: true, message: "Please enter watch count!" },
                                    { type: "number", min: 0, message: "Count must be 0 or more" },
                                ]}
                            >
                                <InputNumber min={0} style={{ width: "15%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}> <label>Coefficients Variables</label>
                            <Row>
                                {[...Array(5)].map((_, index) => (
                                    <Col span={4}>
                                        <Form.Item
                                            key={`k${index + 1}`}
                                            label={`K${index + 1}`}
                                            name={`k${index + 1}`}
                                            rules={[
                                                { required: true, message: `Please enter K${index + 1}!` },
                                                { type: "number", min: 0, message: "Count must be 0 or more" },
                                            ]}
                                        >
                                            <InputNumber min={0} style={{ width: "90%" }} />
                                        </Form.Item>
                                    </Col>))}
                            </Row></Col>
                        <Col span={24}>  <label>Performance Weighting</label>
                            <Row>
                                {[...Array(4)].map((_, index) => (
                                    <Col span={4}>
                                        <Form.Item
                                            key={`w${index + 1}`}
                                            label={`W${index + 1}`}
                                            name={`w${index + 1}`}
                                            rules={[
                                                { required: true, message: `Please enter W${index + 1}!` },
                                                { type: "number", min: 0, message: "Count must be 0 or more" },
                                            ]}
                                        >
                                            <InputNumber min={0} style={{ width: "90%" }} />
                                        </Form.Item>
                                    </Col>
                                ))}
                            </Row>
                        </Col>

                    </Row>
                    <Form.Item>
                        <button
                            className="add-btn" onClick={handleSave}>
                            Save
                        </button>
                    </Form.Item>


                </Form >
            </Card >
        </>
    );
};

export default Setting;
