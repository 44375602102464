import { message } from "antd";
import { API } from "../../../../config/api/Index";
import { DataService } from "../../../../config/DataService";

export const sectionProgressList = (page, current, search, courseId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.learner.section.list}?page=${page}&limit=${current}${search == "" ? "" : "&search=" + search
        }&courseId=${courseId}`
      );
      if (response.data.status) {
        dispatch({
          type: API.learner.section.list,
          list: response.data.data,
          listCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const sectionLearnerProgressList = (sectionId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.learner.section.detail + sectionId}`
      );
      if (response.data.status) {
        dispatch({
          type: API.learner.section.detail,
          detail: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const sectionTrainerProgressList = (
  page,
  current,
  search,
  userId,
  courseId
) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        `${API.trainer.section.list + "/" + userId
        }?page=${page}&limit=${current}${search == "" ? "" : "&search=" + search
        }&courseId=${courseId}`
      );
      if (response.data.status) {
        dispatch({
          type: API.trainer.section.list,
          list: response.data.data,
          listCounts: {
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          },
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const sectionProgressDetails = (sectionId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.learner.section.details + sectionId
      );
      if (response.data.status) {
        dispatch({
          type: API.learner.section.details,
          details: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export const sectionQuestionCardDetails = (userId, sectionId) => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(
        API.trainer.section.details + userId + "/" + sectionId
      );
      if (response.data.status) {
        dispatch({
          type: API.trainer.section.details,
          details: response.data.data,
        });
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const resetCountDetails = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.admin.setting.resetVideoCount, payload);

      if (response.data.status) {
        dispatch({
          type: "RESET_VIDEO_COUNT",
          payload: response.data.data,
        });
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      console.error("API Error:", err);
      message.error("Failed to reset video count.");
      return false;
    }
  };
};
