import { Col, Flex, Progress, Row } from "antd";
import AOS from "aos";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFirstLetters } from "../../../../config/CommonFuntion";
import { statisticsList } from "../../../../redux/user/learner/statistics/action";
import { getItem } from "../../../../utility/localStorageControl";
import styles from "./style.module.css";
const twoColors = {
  "0%": "#1D0F3F",
  "100%": "#A266E9"
};
// DEFINE MAIN FUNCTION
const Index = ({ width }) => {
  // DEFINE ALL STATES
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentLatestVideoIndex, setCurrentLatestVideoIndex] = useState(0);
  const userDetails = getItem("userDetails");
  const { t } = useTranslation();
  const [percent, setPercent] = useState(0);
  const [percentQue, setPercentQue] = useState(0);

  // DEFINE USEEFFECT
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    list();
  }, []);
  // DEFINE FUNCTION FOR STATISTIC LIST
  const list = async () => {
    await dispatch(statisticsList());
  };
  let listOfStatistics = useSelector((state) => state.statistic.list);

  const config = {
    data: [
      { name: "one", year: "SEP", value: 2 },
      { name: "one", year: "OCT", value: 1 },
      { name: "one", year: "NOV", value: 3 },
      { name: "one", year: "DEC", value: 2.5 },
      { name: "one", year: "JAN", value: 3 },
      { name: "one", year: "FEB", value: 2 },
      { name: "two", year: "SEP", value: 3 },
      { name: "two", year: "OCT", value: 2 },
      { name: "two", year: "NOV", value: 4 },
      { name: "two", year: "DEC", value: 3.5 },
      { name: "two", year: "JAN", value: 4 },
      { name: "two", year: "FEB", value: 3 },
    ],
    xField: "year",
    yField: "value",
    seriesField: "name",
    yAxis: {
      label: {
        formatter: (v) => `${(v / 10e8).toFixed(1)} B`,
      },
    },
    legend: {
      position: "top",
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };

  const configs = {
    data: [
      {
        type: "ONE",
        sales: 10,
      },
      {
        type: "TWO",
        sales: 30,
      },
      {
        type: "THREE",
        sales: 20,
      },
      {
        type: "FOUR",
        sales: 20,
      },
      {
        type: "FIVE",
        sales: 25,
      },
      {
        type: "SIX",
        sales: 28,
      },
    ],
    xField: "type",
    yField: "sales",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "LEARN",
      },
      sales: {
        alias: "TRAINER",
      },
    },
    minColumnWidth: 2,
    maxColumnWidth: 2,
  };

  const pieConfig = {
    appendPadding: 10,
    data: [
      {
        type: "Learner",
        value: 25,
      },
      {
        type: "Provider",
        value: 15,
      },
      {
        type: "Trainer",
        value: 60,
      },
    ],
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    labelLine: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  const latestTrainingReviewsVideo = [
    {
      name: "Learning Module 7",
      duration: "11 minutes",
      index: "1",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg1.png",
        "../../../images/user/latestVideoImg2.png",
        "../../../images/user/latestVideoImg3.png",
        "../../../images/user/latestVideoImg4.png",
      ],
    },
    {
      name: "Learning Module 12",
      duration: "22 minutes",
      index: "2",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "Learning Module 12",
      duration: "22 minutes",
      index: "3",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "Learning Module 12",
      duration: "22 minutes",
      index: "4",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "Learning Module 12",
      duration: "22 minutes",
      index: "5",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "Learning Module 12",
      duration: "22 minutes",
      index: "6",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "Learning Module 7",
      duration: "11 minutes",
      index: "7",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg1.png",
        "../../../images/user/latestVideoImg2.png",
        "../../../images/user/latestVideoImg3.png",
        "../../../images/user/latestVideoImg4.png",
      ],
    },
    {
      name: "Learning Module 12",
      duration: "22 minutes",
      index: "8",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "Learning Module 12",
      duration: "22 minutes",
      index: "9",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "Learning Module 12",
      duration: "22 minutes",
      index: "10",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "Learning Module 12",
      duration: "22 minutes",
      index: "11",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
    {
      name: "Learning Module 12",
      duration: "22 minutes",
      index: "12",
      rate: true,
      images: [
        "../../../images/user/latestVideoImg2-1.png",
        "../../../images/user/latestVideoImg2-2.png",
        "../../../images/user/latestVideoImg2-3.png",
        "../../../images/user/latestVideoImg2-4.png",
      ],
    },
  ];

  const latestTrainingReviewsVideoLength = 12;

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={styles.userDetailsContent} data-aos="fade-right">
        <div className={styles.container}>
          <div className={styles.image}>
            {getFirstLetters(userDetails?.name)}{" "}
          </div>
          <div className={styles.details}>
            <div className={styles.name}>
              {t("Welcome")} {userDetails?.name}
            </div>
            <div className={styles.description}>
              {t("You are in the")} {userDetails?.organization?.name}{" "}
              {t("Learning Industries environment")}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.latestVideoContent} data-aos="fade-left">
        <Row gutter={12} className="d-flex justify-content-center">
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <div
              className={styles.exploreCard}
              onClick={() => {
                navigate("/company/workSpace");
              }}
            ></div>
            <div className={styles.exploreBackImageCardTextDiv}>
              <span>{t("Explore my")}</span>
              <br />
              <span>{t("environment")}</span>
            </div>
            <div
              className={styles.exploreBackCardButtonDiv}
              onClick={() => {
                navigate("/company/workSpace");
              }}
            >
              {" "}
              <FeatherIcon icon="chevron-right" size={36} />
            </div>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={12}
            md={24}
            sm={24}
            xs={24}
            className={styles.cardDiv}
          >
            <div className={styles.courseCard}>
              <div className={styles.courseCardTextDiv}>
                <span>{t("Learning courses")}</span>
              </div>
              <div className={styles.courseCardProgressDiv}>
                <Flex gap="small" wrap>
                  <Progress
                    className={styles.antProgress}
                    type="circle"
                    percent={listOfStatistics?.course?.completionPercentage}
                    strokeWidth={10}
                    strokeColor={twoColors}
                  />
                </Flex>
              </div>
              <div className={styles.courseCardDescDiv}>
                <i class="fa-solid fa-graduation-cap"></i> {t("Total Course")}:
                <span>{listOfStatistics?.course?.totalLearningCourse}</span>
              </div>
              <div className={styles.courseCardDesDiv}>
                <i class="fa-regular fa-circle-check"></i> {t("Completed")}:
                <span>{listOfStatistics?.course?.completedCourses}</span>
              </div>
            </div>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={12}
            md={24}
            sm={24}
            xs={24}
            className={styles.cardDiv2}
          >
            <div className={styles.courseCard}>
              <div className={styles.courseCardTextDiv}>
                <span>{t("Questions")}</span>
              </div>
              <div className={styles.courseCardProgressDiv}>
                <Flex gap="small" wrap>
                  <Progress
                    className={styles.antProgress}
                    type="circle"
                    percent={listOfStatistics?.questions?.correctionRatio}
                    strokeWidth={10}
                    strokeColor={twoColors}
                  />
                </Flex>
              </div>
              <div className={styles.queCardDescDiv}>
                <i class="fa-solid fa-circle-question"></i>{" "}
                {t("Total Questions")}:
                <span> {listOfStatistics?.questions?.totalQuestions}</span>
              </div>
              <div className={styles.queCardDesDiv}>
                <i class="fa-regular fa-circle-right"></i> {t("Answered")}:
                <span> {listOfStatistics?.questions?.answeredQuestions}</span>
              </div>
              <div className={styles.queCardDeDiv}>
                <i class="fa-regular fa-circle-check"></i> {t("Correct Answer")}
                :<span> {listOfStatistics?.questions?.rightAnswers}</span>/{" "}
                {listOfStatistics?.questions?.answeredQuestions}
              </div>
            </div>
          </Col>
          {/* <Col
            xxl={16}
            xl={width < 1296 ? 24 : 16}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <div className={styles.latestVideoSlogen}>
              <span>
                {t("Save Training Video to Favorites for Later Review")}
              </span>
              <div className={styles.latestVideoButtons}>
                <div
                  className={styles.latestVideoPrevButton}
                  onClick={() => {
                    if (currentLatestVideoIndex !== 0) {
                      setCurrentLatestVideoIndex(currentLatestVideoIndex - 1);
                    }
                  }}
                >
                  <FeatherIcon
                    color={currentLatestVideoIndex !== 0 ? "black" : "#c8cace"}
                    icon="arrow-left"
                    size={22}
                  />
                </div>
                <div
                  className={styles.latestVideoNextButton}
                  onClick={() => {
                    if (width > 765) {
                      if (
                        latestTrainingReviewsVideoLength !==
                        currentLatestVideoIndex +
                          latestTrainingReviewsVideoLength / 2 +
                          1
                      ) {
                        setCurrentLatestVideoIndex(currentLatestVideoIndex + 1);
                      }
                    } else {
                      if (
                        latestTrainingReviewsVideoLength !==
                        currentLatestVideoIndex + 1
                      ) {
                        setCurrentLatestVideoIndex(currentLatestVideoIndex + 1);
                      }
                    }
                  }}
                >
                  <FeatherIcon
                    color={
                      width > 765
                        ? latestTrainingReviewsVideoLength !==
                          currentLatestVideoIndex +
                            latestTrainingReviewsVideoLength / 2 +
                            1
                          ? "black"
                          : "#c8cace"
                        : latestTrainingReviewsVideoLength !==
                          currentLatestVideoIndex + 1
                        ? "black"
                        : "#c8cace"
                    }
                    icon="arrow-right"
                    size={22}
                  />
                </div>
              </div>
            </div>
            <Row gutter={12}>
              <div className={styles.carousel}>
                <div
                  className={styles.carouselItems}
                  style={{
                    transform: `translateX(-${currentLatestVideoIndex * 100}%)`,
                  }}
                >
                  {latestTrainingReviewsVideo.map((value, index) => {
                    return (
                      <>
                        <Col
                          key={index}
                          xxl={12}
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={24}
                        >
                          <div className={styles.latestVideoModuleCard}>
                            <div
                              className={styles.latestVideoModuleCardImagesDiv}
                            >
                              <div>
                                <img
                                  src={require(`../../../../assets/images/user/latestVideoImg${
                                    index == 0 ? "" : `${index + 1}-`
                                  }1.png`)}
                                />
                                <img
                                  src={require(`../../../../assets/images/user/latestVideoImg${
                                    index == 0 ? "" : `${index + 1}-`
                                  }2.png`)}
                                />
                              </div>
                              <div>
                                <img
                                  src={require(`../../../../assets/images/user/latestVideoImg${
                                    index == 0 ? "" : `${index + 1}-`
                                  }3.png`)}
                                />
                                <img
                                  src={require(`../../../../assets/images/user/latestVideoImg${
                                    index == 0 ? "" : `${index + 1}-`
                                  }4.png`)}
                                />
                              </div>
                            </div>
                            <div
                              className={styles.latestVideoModuleCardNameDiv}
                            >
                              {value.index} - {value.name}
                            </div>
                            <div
                              className={styles.latestVideoModuleCardDetailsDiv}
                            >
                              <div></div>
                              <div className={styles.latestVideotime}>
                                <span>
                                  <svg
                                    width="30px"
                                    height="30px"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M21.993 7.95a.96.96 0 0 0-.029-.214c-.007-.025-.021-.049-.03-.074-.021-.057-.04-.113-.07-.165-.016-.027-.038-.049-.057-.075-.032-.045-.063-.091-.102-.13-.023-.022-.053-.04-.078-.061-.039-.032-.075-.067-.12-.094-.004-.003-.009-.003-.014-.006l-.008-.006-8.979-4.99a1.002 1.002 0 0 0-.97-.001l-9.021 4.99c-.003.003-.006.007-.011.01l-.01.004c-.035.02-.061.049-.094.073-.036.027-.074.051-.106.082-.03.031-.053.067-.079.102-.027.035-.057.066-.079.104-.026.043-.04.092-.059.139-.014.033-.032.064-.041.1a.975.975 0 0 0-.029.21c-.001.017-.007.032-.007.05V16c0 .363.197.698.515.874l8.978 4.987.001.001.002.001.02.011c.043.024.09.037.135.054.032.013.063.03.097.039a1.013 1.013 0 0 0 .506 0c.033-.009.064-.026.097-.039.045-.017.092-.029.135-.054l.02-.011.002-.001.001-.001 8.978-4.987c.316-.176.513-.511.513-.874V7.998c0-.017-.006-.031-.007-.048zm-10.021 3.922L5.058 8.005 7.82 6.477l6.834 3.905-2.682 1.49zm.048-7.719L18.941 8l-2.244 1.247-6.83-3.903 2.153-1.191zM13 19.301l.002-5.679L16 11.944V15l2-1v-3.175l2-1.119v5.705l-7 3.89z"></path>
                                  </svg>
                                </span>
                                <span>{value.duration}</span>
                              </div>
                              <div className={styles.latestVideoRate}>
                                {value.rate ? (
                                  <svg
                                    id="SvgjsSvg10011"
                                    width="24px"
                                    height="24px"
                                    version="1.1"
                                  >
                                    <defs id="SvgjsDefs1002"></defs>
                                    <g id="SvgjsG1008">
                                      <svg
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        height="24px"
                                      >
                                        <path
                                          fill="#6563ff"
                                          d="M17.562 21.56a1 1 0 0 1-.465-.116L12 18.764l-5.097 2.68a1 1 0 0 1-1.45-1.053l.973-5.676-4.124-4.02a1 1 0 0 1 .554-1.705l5.699-.828 2.549-5.164a1.04 1.04 0 0 1 1.793 0l2.548 5.164 5.699.828a1 1 0 0 1 .554 1.705l-4.124 4.02.974 5.676a1 1 0 0 1-.985 1.169Z"
                                        ></path>
                                      </svg>
                                    </g>
                                  </svg>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </div>
              </div>
            </Row>
          </Col> */}
        </Row>
      </div>
      <div className={styles.dashboardDetailsContent} data-aos="fade-right">
        <Row gutter={12}>
          {" "}
          {/* <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <span className={styles.tutorialLabel}> {t("Tutorial")} </span>
            <div className={styles.tutorialCard}>
              <video
                className={styles.tutorialCardImg}
                autoPlay
                loop
                muted
                src={require("../../../../assets/images/audio/big_buck_bunny_720p_1mb.mp4")}
              />
              <div
                className={styles.tutorialCardButton}
                onClick={() => {
                  navigate("/company/workSpace");
                }}
              >
                <label>{t("See more")}</label>
              </div>
            </div>
          </Col> */}
          {/* <Col
            style={{ paddingLeft: width < 1230 ? "6px" : "20px" }}
            xxl={16}
            xl={width < 1230 ? 24 : 16}
            lg={24}
            md={24}
            sm={24}
            xs={24}
          >
            <div className={styles.StaticsContent}>
              <span className={styles.StaticsContentLabel}>
                {t("Statistics")}
              </span>
              <div className={styles.staticContentCards}>
                <Row gutter={25}>
                  <Col
                    xxl={14}
                    xl={width < 1300 ? 12 : 14}
                    lg={14}
                    md={12}
                    sm={24}
                    xs={24}
                  >
                    <div className={styles.staticTrackChart}>
                      <Row gutter={12}>
                        {" "}
                        <Col
                          xxl={8}
                          xl={width < 1300 && width > 1230 ? 10 : 8}
                          lg={8}
                          md={width < 948 ? 12 : 8}
                          sm={24}
                          xs={24}
                        >
                          <div className={styles.detailsContent}>
                            <span className={styles.chartButton}>
                              {" "}
                              <FeatherIcon icon="calendar" size={22} />{" "}
                              {t("This Month")}
                            </span>
                            <span className={styles.chartAmount}>$37.5K</span>
                            <span className={styles.chartAmountAverage}>
                              <span>{t("Total Spent")}</span>
                              <span>+2.45%</span>
                            </span>
                            <span className={styles.chartStatus}>
                              {" "}
                              <svg
                                style={{ height: "20px", height: "20px" }}
                                version="1.1"
                                id="Capa_1"
                                viewBox="0 0 50 50"
                              >
                                <circle
                                  style={{ fill: "#25AE88" }}
                                  cx="25"
                                  cy="25"
                                  r="25"
                                />
                                <polyline
                                  style={{
                                    fill: "none",
                                    stroke: "#FFFFFF",
                                    strokeWidth: "2",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                  }}
                                  points="  38,15 22,33 12,25 "
                                />
                              </svg>
                              {t("On Track")}
                            </span>
                          </div>
                        </Col>
                        <Col
                          xxl={16}
                          xl={width < 1300 && width > 1230 ? 14 : 16}
                          lg={16}
                          md={width < 948 ? 12 : 16}
                          sm={24}
                          xs={24}
                        >
                          <div className={styles.staticChart}>
                            <Line {...config} />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col
                    xxl={10}
                    xl={width < 1300 ? 12 : 10}
                    lg={10}
                    md={12}
                    sm={24}
                    xs={24}
                  >
                    <div className={styles.staticDailyTrackChart}>
                      <div className={styles.staticDailyChartDetails}>
                        <div className={styles.staticDailyChartNames}>
                          <span>{t("Daily Traffic")}</span>
                          <span>
                            <label>2.579</label> <label>{t("Visitors")} </label>
                          </span>
                        </div>
                        <div className={styles.staticDailyChartAmount}>
                          +2.45%
                        </div>
                      </div>
                      <div className={styles.staticDailyChartChart}>
                        <Column {...configs} />
                      </div>
                    </div>
                  </Col>
                </Row>{" "}
              </div>
              <div style={{ display: "flex" }}>
                <div className={styles.staticContentPieChart}>
                  <div className={styles.staticAverageTrackChart}>
                    <div className={styles.staticAverageTrackChartCard}>
                      <div className={styles.staticAverageTrackChartName}>
                        <span>{t("Your Pie Chart")}</span>
                        <span>{t("Monthly")}</span>
                      </div>
                      <div className={styles.staticAverageTrackChartPart}>
                        <Pie {...pieConfig} />
                      </div>
                      <div className={styles.staticAverageTrackChartNumbers}>
                        <span>
                          <label></label>
                          {t("Your Files")}
                          <br />
                          36%
                        </span>
                        <span>
                          <label></label>
                          {t("System")}
                          <br />
                          25%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.staticContentPieBlankChartCard}></div>
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Index;
