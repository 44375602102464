import React, { useEffect, useState, useRef } from "react";
import Aos from "aos";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { otpVerification } from "../../../redux/authentication/action";
import styles from "./style.module.css";

const OtpVerify = () => {
  // DEFINE ALL STATES
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [error, setError] = useState(false); // State to handle error
  const inputRefs = useRef([]);

  // DEFINE USEEFFECT
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  // Handle OTP Input
  const handleChange = (index, value) => {
    if (!/^\d*$/.test(value)) return; // Only allow numbers
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    setError(false); // Reset error on change

    // Auto-focus next input
    if (value && index < 3) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  // Handle Backspace
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  // LOGIN FORM SUBMIT FUNCTION DEFINE
  const onFinish = async () => {
    if (otp.includes("")) {
      setError(true);
      return;
    }

    const userId = window.location.pathname.split("/").pop();
    let payload = {
      userId: userId,
      otp: otp.join(""), // Combine 4 boxes into a single string
    };
    let resp = await dispatch(otpVerification(payload));
    if (resp) {
      navigate(`/reset-password/${userId}`);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.LoginComponent}>
          <div className={styles.loginContent}>
            <div className={styles.loginDetails}>
              <h5 data-aos="fade-right"> {t("OTP Verification")} </h5>
              <p data-aos="fade-left">
                {t("Please enter the OTP sent to your registered Email ID")}
              </p>
            </div>
            <div className={styles.loginFormSection}>
              <Form layout="vertical" onFinish={onFinish}>
                <Form.Item data-aos="fade-right">
                  <div className={styles.otpContainer}>
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        maxLength={1}
                        className={styles.otpInput}
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                      />
                    ))}
                  </div>
                  {error && (
                    <p className={styles.errorText}>OTP is required</p>
                  )}
                </Form.Item>
                <Form.Item>
                  <button
                    className={styles.loginButton}
                    type="primary"
                    htmlType="submit"
                  >
                    {t("Submit")}
                  </button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className={styles.backgroundImageContent}>
            <div className={styles.imageContainer}>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAACtBAMAAAC0KMWCAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAVUExURUdwTPHx9vDx9vDx9vHy9/Pz+e/w9XrTQnAAAAAGdFJOUwB7ptFPJqEDGaYAAAKASURBVHja7d3NacNAFIVRb1KAUoJUgkB7g0qIW1D/JcSEkEVwyJ/BulfnlKB8DO/NGHI6QYHLyzoM4zT7EuR6uqzr8HzNePvgoxB4GJ+HcZyX7Qafh5DD+H2muJmxoEmZKc7XmWKet5/yzciaKQRN6oK3CZq0w/jXM4WgSV3wBE3Vgido9rjgDX9d8ARN1YInaHaQ8fyAjAXNbu8pBE3VPYWg6R+NBU3qPYWgOeZhLGi+P4zffhUUl7Gg+TxTTCEzhaD5YqbY022boPnPTLFsrfyNLXiCJuEwLp0pBH2s07hpwRP04S8qtsPTQvxFxbTIWND5FxXaFXT+RYXDWNAFjx8yFrQFT9CYKQSNBU/QRz+MzRSCzj+MLXiCNlMg6P284MlY0AWHsZlC0OELnsNY0F7wEPROXvBkLOj8FzwzhaAteAjaCx6CvseCJ2NBmykQtAUPQd/jV0EyFnTBgmemELQFD0E//AXPgkdy0BY8CoJ220ZB0BY88oO24FEQtJmC/KAteBQE7QWP/KC94FEQtJmCgqC94JEftMOYgqAL/kc0grbgkR+0FzwKgvaCR37QFjwKgvaCR0HQFjw6gjZTUBW0T4CgQdAgaBA0ggZBg6BB0CBoBA2CBkGDoEHQCBoEDYIGQYOgETQIGgQNggZBI2gQNAgaBA2CRtAgaBA0CBoEjaBB0CBoEDSCBkGDoEHQIGgEDYIGQYOgQdAIGgQNggZBg6ARNAgaBA2CBkEjaBA0CBoEDYJG0CBoEDQIGgSNoEHQIGgQNAgaQYOgQdAgaAQNggZBg6BB0AgaBA2CBkGDoBE0CBoEDYIGQSNoEDQIGgQNgkbQIGgQNAgaBE2rV8fAx8SI153KAAAAAElFTkSuQmCC" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerify;
