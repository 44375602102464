import { API } from "../../../../config/api/Index";

//ALL TYPE WISE DATA RETURN
const initState = {
  learningList: undefined,
  notLearningList: undefined,
  allCourseList: [],
  allLearnerCourseList: [],
  allStatisticsList: [],
  allCourseListCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
  notLearningCourseListCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
  learningCourseListCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
  allLearnerCourseListCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

//DEFINE AUTH REDUCER FUNCTION
const LearnerCourseReducer = (state = initState, action) => {
  switch (action.type) {
    case API.learner.course.notLearningList:
      return {
        ...state,
        notLearningList: action.notLearningList,
        notLearningCourseListCounts: action.notLearningCourseListCounts,
      };
    case API.learner.course.allList:
      return {
        ...state,
        allCourseList: action.allCourseList,
        allCourseListCounts: action.allCourseListCounts,
      };
    case API.trainer.course.allList:
      return {
        ...state,
        allLearnerCourseList: action.allCourseList,
        allLearnerCourseListCounts: action.allCourseListCounts,
      };
    case API.trainer.course.allStatistic:
      return {
        ...state,
        allStatisticsList: action.allStatisticsList,
      };
    case API.learner.course.learningList:
      return {
        ...state,

        learningList: action.learningList,
        learningCourseListCounts: action.learningCourseListCounts,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default LearnerCourseReducer;
