import { message } from "antd";
import { t } from "i18next";

// DEFINE FUNCTION FOR WORD FIRST LETTER CAPITAL
export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

// DEFINE FUNCTION FOR IMAGE TYPE CHECK VALIDATION
export const onUploadImageValidation = (e) => {
  e.stopPropagation();
  e.preventDefault();

  const file = e.target.files[0];

  if (!file) {
    message.error(t("Please select an image"));
    return false;
  }

  const fileType = file.type;
  const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];

  if (!validImageTypes.includes(fileType)) {
    message.error(t("Please select only JPG or PNG images"));
    return false;
  }

  return true;
};

// DEFINE FUNCTION FOR VIDEO TYPE CHECK VALIDATION
export const onUploadVideoValidation = (e) => {
  e.stopPropagation();
  e.preventDefault();

  const file = e.target.files[0];

  if (!file) {
    message.error(t("Please select video"));
    return false;
  }

  const fileType = file.type;
  const validVideoTypes = ["video/mp4", "video/mov", "video/quicktime"];

  if (!validVideoTypes.includes(fileType)) {
    message.error(t("Please select only mp4 videos"));
    return false;
  }

  return true;
};

// DEFINE MOBILE NUMBER FORMAT CHANGE
export const handleMobileNumberFormat = (input, code) => {
  let formattedInput = "";
  if (code === "+91") {
    let num = input.slice(0, 10);
    for (let i = 0; i < num.length; i++) {
      formattedInput += num[i];
      if ((i + 1) % 5 === 0 && i !== num.length - 1) {
        formattedInput += " ";
      }
    }
  } else {
    let num = input.slice(0, 9);
    for (let i = 0; i < num.length; i++) {
      formattedInput += num[i];
      if (i % 2 === 0 && i !== num.length - 1) {
        formattedInput += " ";
      }
    }
  }

  return formattedInput;
};

// DEFINE FUNCTION FOR WORDS IN FIND FIRTS AND SECOND LATTER
export const getFirstLetters = (str) => {
  const words = str?.split(" ");

  const firstLetters = words?.map((word) => word?.charAt(0)?.toUpperCase());

  const result = firstLetters?.join("");

  return result;
};

// DEFINE FUNCTION FOR WORD FIRST LETTER CAPITAL
export const capitalizeFirstAndWordLetter = (firstString) => {
  if (firstString !== "") {
    return firstString
      ?.split(" ") // Split the name string into words
      .slice(0, 2)
      ?.filter((word) => word.length > 0) // Filter out empty words
      ?.map((word) => word[0].toUpperCase()) // Map each word to its first character (uppercase)
      ?.join(""); // Join the initials into a single string
  } else {
    return "";
  }
};

// DEFINE FUNCTION FOR CONVERT TO SECONDS TO ACTUAL TIMING
export const convertSecondsToHMS = (seconds) => {
  const hours = Math.floor(Number(seconds) / 3600);
  const minutes = Math.floor((Number(seconds) % 3600) / 60);
  const remainingSeconds = Number(seconds) % 60;

  return `${hours == 0 ? "" : hours < 10 ? `0${hours} : ` : hours + " : "}${minutes < 10 ? `0${minutes}` : minutes
    } : ${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
};

export function convertDurationtoSeconds(duration) {
  const [hours, minutes, seconds] = duration.split(":");
  return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
}

// DEFINE FUNCTION FOR CONVERT TO SECONDS TO ACTUAL TIMING
export const convertSecondsToHoursMinuitesSeconds = (seconds) => {
  const hours = Math.floor(Number(seconds) / 3600);
  const minutes = Math.floor((Number(seconds) % 3600) / 60);
  const remainingSeconds = Number(seconds) % 60;
  return `${hours}:${minutes}:${remainingSeconds}`;
};

// DEFINE FUNCTION FOR CONVERT TO SECONDS TO ACTUAL TIMING
export const convertSecondsToHours = (seconds) => {
  const hours = Math.floor(Number(seconds) / 3600);
  return hours;
};

// DEFINE FUNCTION FOR CONVERT TO SECONDS TO ACTUAL TIMING
export const convertSecondsToMinutes = (seconds) => {
  const minutes = Math.floor((Number(seconds) % 3600) / 60);
  return minutes;
};

// DEFINE FUNCTION FOR CONVERT TO SECONDS TO ACTUAL TIMING
export const convertSecondsToSeconds = (seconds) => {
  const remainingSeconds = Number(seconds) % 60;
  return remainingSeconds;
};

// DEFINE FUNCTION FOR DATE BASED DIFFERENT BETWEEN TO CURRENT DATE TO THIS DATE
export const formatDateFromNow = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diff = now - date;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  // if (months > 0) {
  //   return `${months} ${t("month")}${months > 1 ? "s" : ""} ${t("ago")}`;
  // } else if (days > 0) {
  //   return `${days} ${t("day")}${days > 1 ? "s" : ""} ${t("ago")}`;
  // } else if (hours > 0) {
  //   return `${hours} ${t("hour")}${hours > 1 ? "s" : ""} ${t("ago")}`;
  // } else if (minutes > 0) {
  //   return `${minutes} ${t("minute")}${minutes > 1 ? "s" : ""} ${t("ago")}`;
  // } else {
  //   return `${seconds} ${t("second")}${seconds !== 1 ? "s" : ""} ${t("ago")}`;
  // }

  if (months > 0) {
    return t("monthsAgo", { count: months });
  } else if (days > 0) {
    return t("daysAgo", { count: days });
  } else if (hours > 0) {
    return t("hoursAgo", { count: hours });
  } else if (minutes > 0) {
    return t("minutesAgo", { count: minutes });
  } else {
    return t("secondsAgo", { count: seconds });
  }
};

// DEFINE FUNCTION FOR UPDATE ARRAY
export const findAndUpdateArray = (
  defaultaArray,
  findingValues,
  updatesValue
) => {
  defaultaArray.forEach((item) => {
    let shouldUpdate = true;
    if (Object.keys(findingValues).length > 0) {
      for (let key in findingValues) {
        if (findingValues[key] !== item[key]) {
          shouldUpdate = false;
          break;
        }
      }
    } else {
      shouldUpdate = false;
    }
    if (shouldUpdate) {
      for (let key in updatesValue) {
        if (item.hasOwnProperty(key)) {
          item[key] = updatesValue[key];
        }
      }
    }
  });
  return defaultaArray;
};

export const findAndUpdateOptionArray = (
  defaultaArray = [],
  findingKey,
  findOption,
  updatesValue
) => {
  let findObjectKey = Object.keys(findingKey);
  let findObjectOption = Object.keys(findOption);
  let findKeyIndex = defaultaArray.findIndex(
    (e) => e[findObjectKey] === findingKey[findObjectKey]
  );
  if (findKeyIndex >= 0) {
    let findChildrenIndex = defaultaArray[findKeyIndex].children.findIndex(
      (e) => e[findObjectOption] === findOption[findObjectOption]
    );
    for (let key in updatesValue) {
      defaultaArray[findKeyIndex].children[findChildrenIndex][key] =
        updatesValue[key];
    }
    return defaultaArray;
  } else {
    return defaultaArray;
  }
};

export const findAndAddOptionArray = (
  defaultaArray = [],
  findingKey,
  findOption
) => {
  let findObjectKey = Object.keys(findingKey);
  let findKeyIndex = defaultaArray.findIndex(
    (e) => e[findObjectKey] === findingKey[findObjectKey]
  );
  if (findKeyIndex >= 0) {
    defaultaArray[findKeyIndex].children = [
      ...defaultaArray[findKeyIndex].children,
      findOption,
    ];
    return defaultaArray;
  } else {
    return defaultaArray;
  }
};

export const findAndDeleteOptionArray = (
  defaultaArray = [],
  findingKey,
  findOption
) => {
  let findObjectKey = Object.keys(findingKey);
  let findObjectOption = Object.keys(findOption);
  let findKeyIndex = defaultaArray.findIndex(
    (e) => e[findObjectKey] === findingKey[findObjectKey]
  );
  if (findKeyIndex >= 0) {
    defaultaArray[findKeyIndex].children = defaultaArray[
      findKeyIndex
    ].children.filter(
      (item) => item[findObjectOption] !== findOption[findObjectOption]
    );
    return defaultaArray;
  } else {
    return defaultaArray;
  }
};

export function addObjectInArray(defaultaArray, addValues) {
  let checkObject =
    typeof addValues === "object" &&
    !Array.isArray(addValues) &&
    addValues !== null;
  if (checkObject) {
    if (Object.keys(addValues).length > 0) {
      return [...defaultaArray, addValues];
    } else {
      return defaultaArray;
    }
  } else {
    return defaultaArray;
  }
}

// DEFINE FUNCTION FOR DELETE ARRAY
export const deleteFromArray = (array, deleteValues) => {
  const filteredArray = array.filter((item) => {
    let matchesCriteria = true;

    for (let key in deleteValues) {
      if (deleteValues[key] !== item[key]) {
        matchesCriteria = false;
        break;
      }
    }

    return !matchesCriteria;
  });

  return filteredArray;
};

// DEFINE FUNCTION FOR DELETE ARRAY
export const deleteElementFromArray = (array, deleteElement) => {
  return array.filter((e) => e !== Number(deleteElement));
};

// DEFINE FUNCTION FOR CHECK ARRAY IN SAME KEY AND VALUE REMOVE
export function filterUniqueByKey(arr, key) {
  let uniqueMap = arr.reduce((acc, obj) => {
    acc[obj[key]] = obj;
    return acc;
  }, {});

  let result = Object.values(uniqueMap);

  return result;
}

// DEFINE FUNCTION FOR BIG VALUES TO CONVERT LIKE K OR M
export const convertToBigValues = (value) => {
  if (value >= 1000000) {
    value = value / 1000000 + "M";
  } else if (value >= 1000) {
    value = value / 1000 + "K";
  }
  return value;
};
