import { API } from "../../../../config/api/Index";

//ALL TYPE WISE DATA RETURN
const initState = {
  list: undefined,
  listCounts: {
    currentPage: 0,
    totalCount: 0,
    totalPages: 0,
  },
  videoList: [],
};

//DEFINE AUTH REDUCER FUNCTION
const LearnerVideoReducer = (state = initState, action) => {
  switch (action.type) {
    case API.learner.video.list:
      return {
        ...state,
        list: action.list,
        listCounts: action.listCounts,
        videoList: action.videoList,
      };
    case API.learner.video.listDetails:
      return {
        ...state,
        list: action.list,
        listCounts: action.listCounts,
        videoList: action.videoList,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default LearnerVideoReducer;
