import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  Col,
  Empty,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Radio,
  Row,
  Select,
  Skeleton,
  Table,
  Typography,
} from "antd";
import Meta from "antd/es/card/Meta";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  capitalizeFirstLetter,
  onUploadImageValidation,
} from "../../config/CommonFuntion";
import {
  courseAssignedList,
  courseUnAssignedList,
} from "../../redux/admin/course/action";
import {
  memberCreate,
  memberEdit,
  memberList,
} from "../../redux/admin/members/action";
import {
  assignCourseToOrganization,
  organizationDetails,
  unAssignCourseToOrganization,
} from "../../redux/admin/organization/action";

// DEFINE MAIN FUNCTION
const OrganizationDetails = () => {
  // DEFINE ALL STATES
  const { Title } = Typography;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadImageRef = useRef(null);
  const [assignCoursefForm] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [userPageSize, setUserPageSize] = useState(10);
  const [memberModal, setMemberModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedRole, setSelectedRole] = useState("all");
  const [coursePageSize, setCoursePageSize] = useState(10);
  const [modalLoading, setModalLoading] = useState(false);
  const [userSearchData, setUserSearchData] = useState("");
  const [userCurrentPage, setUserCurrentPage] = useState(1);
  const [memberModalType, setMemberModalType] = useState("");
  const [courseSearchData, setCourseSearchData] = useState("");
  const [courseCurrentPage, setCourseCurrentPage] = useState(1);
  const [assignCourseModal, setAssignCourseModal] = useState(false);
  const organizationID = window.location.pathname.split("/").pop();

  // DEFINE USEEFFECT WITH USER SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      userList(userCurrentPage, userPageSize, userSearchData, selectedRole);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [userSearchData]);

  // DEFINE USEEFFECT WITH COURSE SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      coursesList(courseCurrentPage, coursePageSize, courseSearchData);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [courseSearchData]);

  // DEFINE USEEFFECT FOR ORGANIZATION DETAILS DATA
  useEffect(() => {
    details();
  }, []);

  // DEFINE USEEFFECT FOR IMAGE ONCHANGE CHECK IMAGE FIELD
  useEffect(() => {
    form.validateFields(["image"]);
  }, [uploadedFile]);

  // DEFINE ALL LISTS WITH REDUX
  let detailsOfOrganization = useSelector(
    (state) => state.organization.details
  );
  let listOfMember = useSelector((state) => state.member.list);
  let countsOfMember = useSelector((state) => state.member.listCounts);
  let listOfAssignedCourse = useSelector((state) => state.course.assignedList);
  let listOfAssignedCourseCounts = useSelector(
    (state) => state.course.assignedListCounts
  );
  let listOfUnAssignedCourse = useSelector(
    (state) => state.course.unAssignedList
  );
  let listOfUnAssignedCourseCounts = useSelector(
    (state) => state.course.unAssignedListCounts
  );

  // DEFINE FUNCTION FOR ORGANIZATION DETAILS DATA
  const details = async () => {
    await dispatch(organizationDetails(organizationID));
    await dispatch(courseUnAssignedList("", "", "", organizationID));
  };

  // DEFINE FUNCTION FOR ROLE CHANGE
  const onRoleChange = (e) => {
    setUserCurrentPage(1);
    setUserPageSize(10);
    setUserSearchData("");
    userList(1, 10, "", e.target.value, selectedRole);
    setSelectedRole(e.target.value);
    setLoading(true);
  };

  // DEFINE FUNCTION FOR USER TABLE PAGE CHANGE
  const onUserPageChange = async (page, current) => {
    setUserCurrentPage(page);
    setUserPageSize(current);
    setLoading(true);
    userList(page, current, userSearchData, selectedRole);
  };

  // DEFINE FUNCTION FOR COURSE TABLE PAGE CHANGE
  const onCoursePageChange = async (page, current) => {
    setCourseCurrentPage(page);
    setCoursePageSize(current);
    coursesList(page, current, courseSearchData, selectedRole);
  };

  // DEFINE FUNCTION FOR USER LIST DATA
  const userList = async (page, current, search, role) => {
    await dispatch(
      memberList(
        page,
        current,
        search,
        organizationID,
        role == "all" ? "" : role
      )
    );
    setLoading(false);
  };

  // DEFINE FUNCTION FOR COURSE LIST DATA
  const coursesList = async (page, current, search) => {
    await dispatch(courseAssignedList(page, current, search, organizationID));
  };

  // DEFINE CREATE AND UPDATE API LOADING PERCENTAGE
  const loadingPercentage = (val) => {
    if (val == 100) {
      setModalLoading(false);
    }
  };

  // USER FORM SUBMIT FUNCTION DEFINE
  const onMemberFormSubmit = async (values) => {
    setModalLoading(true);
    let formData = new FormData();
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("position", values.position);
    if (memberModalType == "Create") {
      formData.append("organization", organizationID);
      formData.append("role", values.role);
      formData.append("profilePic", uploadedFile);
      formData.append("email", values.email);
    } else {
      if (uploadedFile !== selectedData.profilePic) {
        formData.append("profilePic", uploadedFile);
      }
    }
    if (memberModalType == "Edit") {
      let result = await dispatch(
        memberEdit(formData, selectedData._id, loadingPercentage)
      );
      if (result) {
        setUploadedFile(null);
        setMemberModal(false);
        form.resetFields();
        setMemberModalType("");
        setSelectedData(null);
        setLoading(true);
        userList(userCurrentPage, userPageSize, userSearchData, selectedRole);
      } else {
        setModalLoading(false);
      }
    } else {
      let result = await dispatch(memberCreate(formData, loadingPercentage));
      if (result) {
        setUploadedFile(null);
        setMemberModal(false);
        form.resetFields();
        setMemberModalType("");
        setSelectedData(null);
        setLoading(true);
        userList(
          userCurrentPage,
          userPageSize,
          userSearchData,
          selectedRole,
          loadingPercentage
        );
      } else {
        setModalLoading(false);
      }
    }
  };

  // USER TABLE COLUMNS DATA
  const userTableColumns = [
    {
      title: t("Name"),
      dataIndex: "firstName",
      key: "name",
      width: "33%",
      render: (icon, value) => {
        return (
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              src={
                value.profilePic == ""
                  ? "https://www.svgrepo.com/show/490660/company.svg"
                  : value.profilePic
              }
              size={25}
              alt=""
            />
            <div className="avatar-info">
              <Title level={5}>{value.name}</Title>
              <p>{value.email}</p>
            </div>
          </Avatar.Group>
        );
      },
    },
    {
      title: t("Total Course"),
      key: "courseCount",
      dataIndex: "courseCount",
      width: "20%",
      render: (icon, value) => {
        return (
          <div>
            <span>{value?.courseCount ? value?.courseCount : "0"}</span>
          </div>
        );
      },
    },
    {
      title: t("Role"),
      dataIndex: "role",
      key: "role",
      width: "15%",
      render: (icon, value) => {
        return (
          <div className="author-info">
            <Title level={5}>{capitalizeFirstLetter(value.role)}</Title>
            <p>
              {value.position
                ? capitalizeFirstLetter(value.position)
                : "Manager"}
            </p>
          </div>
        );
      },
    },
    {
      title: t("Created"),
      key: "employed",
      dataIndex: "employed",
      width: "15%",
      render: (icon, value) => {
        return (
          <div>
            <span>{moment(value.createdAt).format("DD-MM-YYYY")}</span>
          </div>
        );
      },
    },
    {
      title: t("Action"),
      key: "action",
      dataIndex: "action",
      width: "7%",
      render: (icon, value) => {
        return (
          <>
            <div className="ant-employed">
              <FeatherIcon
                icon={"edit"}
                onClick={() => {
                  setMemberModal(true);
                  setMemberModalType("Edit");
                  setSelectedData(value);
                  form.setFieldsValue(value);
                  setUploadedFile(value.profilePic);
                }}
                style={{ cursor: "pointer" }}
                color="rgb(11, 35, 34)"
                size={22}
              />
              <FeatherIcon
                icon={"eye"}
                onClick={() => {
                  navigate(`/members/${value._id}`, {
                    replace: true,
                  });
                }}
                style={{ cursor: "pointer" }}
                color="rgb(11, 35, 34)"
                size={22}
              />
            </div>
          </>
        );
      },
    },
  ];

  // DEFINE FUNCTION FOR UPLOAD IMAGE
  const onUploadImageChange = (e) => {
    let result = onUploadImageValidation(e);
    if (result) {
      setUploadedFile(e.target.files[0]);
    }
  };

  // COURSE ASSIGN FUNCTION DEFINE
  const onCourseAssignSubmit = async (values) => {
    let result = await dispatch(
      assignCourseToOrganization(organizationID, values)
    );
    if (result) {
      setAssignCourseModal(false);
      assignCoursefForm.resetFields();
      coursesList(courseCurrentPage, coursePageSize, courseSearchData);
      await dispatch(courseUnAssignedList("", "", "", organizationID));
    }
  };

  // COURSE UNASSIGN FUNCTION DEFINE
  const onUnAssignCourse = async (values) => {
    let result = await dispatch(
      unAssignCourseToOrganization(organizationID, {
        courseId: values._id,
        organizationId: organizationID,
      })
    );
    if (result) {
      coursesList(courseCurrentPage, coursePageSize, courseSearchData);
      await dispatch(courseUnAssignedList("", "", "", organizationID));
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {/* COURSE ASSIGN DEFINE MODAL */}
      <Modal
        title={t("Assign Course")}
        centered
        open={assignCourseModal}
        okText={t("Assign")}
        onOk={() => {
          assignCoursefForm.submit();
        }}
        onCancel={() => {
          assignCoursefForm.resetFields();
          setAssignCourseModal(false);
        }}
      >
        <Form
          layout="vertical"
          form={assignCoursefForm}
          onFinish={onCourseAssignSubmit}
        >
          <Form.Item
            label={t("Course List")}
            name="assignedTo"
            rules={[
              {
                required: true,
                message: t("Please select course!"),
              },
            ]}
          >
            <Select
              showSearch
              size="large"
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder={t("select course")}
              style={{
                minHeight: "45px",
              }}
              options={listOfUnAssignedCourse}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* ADD & EDIT USER DEFINE MOFAL */}
      <Modal
        title={`${t(memberModalType)} ${t("Member")}`}
        centered
        open={memberModal}
        okText={t(memberModalType)}
        onOk={() => {
          if (!modalLoading) {
            form.submit();
          }
        }}
        onCancel={() => {
          if (!modalLoading) {
            form.resetFields();
            setMemberModal(false);
            setMemberModalType("");
            setUploadedFile(null);
            setSelectedData(null);
          }
        }}
        confirmLoading={modalLoading}
      >
        <Form layout="vertical" form={form} onFinish={onMemberFormSubmit}>
          <Row gutter={16}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={t("First Name")}
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t("Please enter member first name!"),
                  },
                ]}
              >
                <Input
                  placeholder={t("enter member first name")}
                  style={{ height: "45px" }}
                />
              </Form.Item>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={t("Last Name")}
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t("Please enter member last name!"),
                  },
                ]}
              >
                <Input
                  placeholder={t("enter member last name")}
                  style={{ height: "45px" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="image"
                rules={[
                  {
                    required: uploadedFile ? false : true,
                    message: t("Please upload member image!"),
                  },
                ]}
                label={t("Image")}
              >
                <div className="orgUploadedIMG">
                  <div
                    style={{
                      width: uploadedFile ? "140px" : "100%",
                      fontSize: uploadedFile ? "12px" : "16px",
                    }}
                    onClick={() => {
                      uploadImageRef.current.click();
                    }}
                  >
                    <UploadOutlined
                      style={{
                        fontSize: uploadedFile ? "16px" : "20px",
                      }}
                    />{" "}
                    {t("Click to Upload")}
                  </div>
                  {uploadedFile ? (
                    <>
                      {" "}
                      <img
                        src={
                          typeof uploadedFile == "string"
                            ? uploadedFile
                            : URL.createObjectURL(uploadedFile)
                        }
                      />
                      <label
                        onClick={() => {
                          setUploadedFile(null);
                          form.resetFields(["image"]);
                        }}
                      >
                        x
                      </label>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <input
                  type="file"
                  id="file"
                  ref={uploadImageRef}
                  style={{ display: "none" }}
                  onChange={onUploadImageChange.bind(this)}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="memberRole"
            >
              <Form.Item
                label={t("Role")}
                name="role"
                rules={[
                  {
                    required: true,
                    message: t("Please select member role!"),
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder={t("select member role")}
                  style={{
                    height: "45px",
                  }}
                  disabled={memberModalType == "Edit" ? true : false}
                  options={[
                    {
                      value: "learner",
                      label: t("Learner"),
                    },
                    {
                      value: "trainer",
                      label: t("Administrator"),
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={t("Email")}
            name="email"
            rules={[
              {
                required: true,
                message: t("Please enter member email!"),
              },
              {
                type: "email",
                message: t("Please enter a valid email!"),
              },
            ]}
          >
            <Input
              disabled={memberModalType == "Edit" ? true : false}
              placeholder={t("enter member email")}
              style={{
                height: "45px",
                color: "#8c8c8c",
                backgroundColor: "white",
              }}
            />
          </Form.Item>
          <Form.Item
            label={t("Position")}
            name="position"
            rules={[
              {
                required: true,
                message: t("Please enter current position!"),
              },
            ]}
          >
            <Input
              placeholder={t("member current position")}
              style={{ height: "45px" }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <div className="tabled">
        <button
          className="add-btn"
          style={{ marginBottom: "20px" }}
          onClick={() => {
            navigate(`/organization`);
          }}
        >
          <FeatherIcon icon="chevron-left" size={18} /> {t("Back")}
        </button>

        {/* DEFINE ORGANIZATION DETAILS COMPONENT */}
        <Row gutter={25}>
          <Col xxl={8} xl={8} lg={8} md={10} sm={24} xs={24}>
            <Card
              bordered={false}
              className="mb-24 organizationProfileImg organizationDetailsImg"
            >
              {" "}
              {detailsOfOrganization ? (
                <img
                  src={
                    detailsOfOrganization?.image == ""
                      ? "https://bootdey.com/img/Content/avatar/avatar7.png"
                      : detailsOfOrganization?.image
                  }
                />
              ) : (
                <Skeleton.Image active={true} size={"large"} />
              )}
              <span className="name">
                {detailsOfOrganization && detailsOfOrganization?.name}
              </span>
            </Card>
          </Col>
          <Col xxl={16} xl={16} lg={16} md={14} sm={24} xs={24}>
            <Card bordered={false} className="mb-24 organizationProfileDetails">
              <Row gutter={25}>
                <Col xxl={6} xl={8} lg={10} md={12} sm={12} xs={12}>
                  <span>{t("Created Date")} :</span>
                </Col>
                <Col xxl={18} xl={16} lg={14} md={12} sm={12} xs={12}>
                  <label>
                    {detailsOfOrganization &&
                      moment(detailsOfOrganization?.createdAt).format(
                        "DD-MM-YYYY"
                      )}
                  </label>
                </Col>
              </Row>
              <Row gutter={25}>
                <Col xxl={6} xl={8} lg={10} md={12} sm={12} xs={12}>
                  <span>{t("Address")} :</span>
                </Col>
                <Col xxl={18} xl={16} lg={14} md={12} sm={12} xs={12}>
                  <label>
                    {" "}
                    {detailsOfOrganization && detailsOfOrganization?.address}
                  </label>
                </Col>
              </Row>
              <Row gutter={25}>
                <Col xxl={6} xl={8} lg={10} md={12} sm={12} xs={12}>
                  <span>{t("Phone Number")} :</span>
                </Col>
                <Col xxl={18} xl={16} lg={14} md={12} sm={12} xs={12}>
                  <label>
                    {" "}
                    {detailsOfOrganization &&
                      detailsOfOrganization?.phoneNumber}
                  </label>
                </Col>
              </Row>
              <Row gutter={25}>
                <Col xxl={6} xl={8} lg={10} md={12} sm={12} xs={12}>
                  <span>{t("Total Course")} :</span>
                </Col>
                <Col xxl={18} xl={16} lg={14} md={12} sm={12} xs={12}>
                  <label>
                    {" "}
                    {detailsOfOrganization && detailsOfOrganization?.courseCount
                      ? detailsOfOrganization?.courseCount
                      : 0}
                  </label>
                </Col>
              </Row>
              <Row gutter={25}>
                <Col xxl={6} xl={8} lg={10} md={12} sm={12} xs={12}>
                  <span>{t("Total Learner")} :</span>
                </Col>
                <Col xxl={18} xl={16} lg={14} md={12} sm={12} xs={12}>
                  <label>
                    {" "}
                    {detailsOfOrganization &&
                      detailsOfOrganization?.learnerCount
                      ? detailsOfOrganization?.learnerCount
                      : 0}
                  </label>
                </Col>
              </Row>
              <Row gutter={25}>
                <Col xxl={6} xl={8} lg={10} md={12} sm={12} xs={12}>
                  <span>{t("Total Administrator")} :</span>
                </Col>
                <Col xxl={18} xl={16} lg={14} md={12} sm={12} xs={12}>
                  <label>
                    {" "}
                    {detailsOfOrganization &&
                      detailsOfOrganization?.trainerCount
                      ? detailsOfOrganization?.trainerCount
                      : 0}
                  </label>
                </Col>
              </Row>
              <Row gutter={25}>
                <Col xxl={6} xl={8} lg={10} md={12} sm={12} xs={12}>
                  <span>{t("Description")} :</span>
                </Col>
                <Col xxl={18} xl={16} lg={14} md={12} sm={12} xs={12}>
                  <label>
                    {" "}
                    {detailsOfOrganization &&
                      detailsOfOrganization?.discription}
                  </label>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        {/* DEFINE USER TABLE LISTING COMPONENT */}
        <Row gutter={25}>
          <Col span={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24 membersCard"
              title={`${selectedRole == "all"
                ? t("Member")
                : t(capitalizeFirstLetter(selectedRole))
                } ${t("Data")}`}
              extra={
                <>
                  {" "}
                  <Input
                    addonBefore={<SearchOutlined />}
                    value={userSearchData}
                    style={{ height: "40px", width: "250px" }}
                    onChange={(e) => setUserSearchData(e.target.value)}
                    placeholder={t("Search member")}
                  />
                  <Radio.Group onChange={onRoleChange} defaultValue="all">
                    <Radio.Button value="all">{t("All")}</Radio.Button>
                    <Radio.Button value="trainer">
                      {t("Administrator")}
                    </Radio.Button>
                    <Radio.Button value="learner">{t("Learner")}</Radio.Button>
                  </Radio.Group>{" "}
                  <button
                    className="add-btn"
                    onClick={() => {
                      setMemberModal(true);
                      setMemberModalType("Create");
                    }}
                  >
                    {t("Add")} <FeatherIcon icon="plus" size={18} />
                  </button>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={userTableColumns}
                  dataSource={listOfMember}
                  loading={loading}
                  pagination={false}
                  className="ant-border-space"
                />
                <div className="tabelsPagenation">
                  <Pagination
                    total={countsOfMember.totalCount}
                    showSizeChanger
                    userPageSizeOptions={[10, 20, 50, 100]}
                    defaultuserPageSize={userPageSize}
                    defaultCurrent={userCurrentPage}
                    onChange={onUserPageChange}
                  />
                </div>
              </div>
            </Card>
            <Card
              extra={
                <>
                  <Input
                    addonBefore={<SearchOutlined />}
                    onChange={(e) => setCourseSearchData(e.target.value)}
                    placeholder={t("Search courses")}
                    size="large"
                  />
                  <button
                    className="add-btn"
                    onClick={() => {
                      setAssignCourseModal(true);
                    }}
                  >
                    {t("Assign")} <FeatherIcon icon="plus" size={18} />
                  </button>
                </>
              }
              bordered={false}
              className="criclebox tablespace mb-24 coursesCard"
              title={t("Courses")}
            >
              <Row gutter={25}>
                {listOfAssignedCourse == null ? (
                  Array.from(Array(8).keys())
                    .map((name) => ({ name }))
                    .map((item, index) => {
                      return (
                        <Col
                          key={index}
                          xxl={6}
                          xl={8}
                          lg={12}
                          md={12}
                          sm={12}
                          className="course-list-skeleton"
                          xs={24}
                        >
                          <Card
                            cover={
                              <Skeleton.Input
                                key={index}
                                active={true}
                                size={"large"}
                              />
                            }
                          >
                            <Meta title={""} description={""} />
                          </Card>
                        </Col>
                      );
                    })
                ) : listOfAssignedCourse && listOfAssignedCourse.length > 0 ? (
                  listOfAssignedCourse.map((value, index) => {
                    return (
                      <>
                        <Col
                          key={index}
                          xxl={6}
                          xl={8}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={24}
                        >
                          <Card
                            onClick={() => {
                              navigate(`/course/${value._id}`, {
                                replace: true,
                              });
                            }}
                            hoverable
                            cover={
                              <img
                                alt=""
                                src={
                                  value.image
                                    ? value.image == ""
                                      ? "https://img-c.udemycdn.com/course/240x135/1362070_b9a1_2.jpg"
                                      : value.image
                                    : "https://img-c.udemycdn.com/course/240x135/1362070_b9a1_2.jpg"
                                }
                              />
                            }
                          >
                            <Meta
                              style={{ padding: "20px" }}
                              title={value.title}
                              description={value.discription}
                            />
                          </Card>

                          <Popconfirm
                            trigger={"hover"}
                            title={t("UnAssign course")}
                            description={t(
                              "Are you sure to UnAssign this course?"
                            )}
                            onConfirm={() => {
                              onUnAssignCourse(value);
                            }}
                            okText={t("Yes")}
                            cancelText={t("No")}
                          >
                            <div className="courseEdit">
                              <FeatherIcon
                                icon="x"
                                size={18}
                                fill="rgb(11, 35, 34)"
                              />
                            </div>
                          </Popconfirm>
                        </Col>
                      </>
                    );
                  })
                ) : (
                  <div className="no-data-component">
                    {" "}
                    <Empty />
                  </div>
                )}
              </Row>

              <div className="tabelsPagenation">
                <Pagination
                  total={listOfAssignedCourseCounts.totalCount}
                  showSizeChanger
                  pageSizeOptions={[10, 20, 50, 100]}
                  defaultPageSize={coursePageSize}
                  defaultCurrent={1}
                  current={courseCurrentPage}
                  onChange={onCoursePageChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default OrganizationDetails;
