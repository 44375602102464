// ALL API END POINTS OBJECT SET-UP
const API = {
  // DEFINE AUTHENTICATION API ROUTE
  admin: {
    dashboard: {
      details: "admin/dashboard",
    },
    auth: {
      login: "auth/login",
      update: "users/",
      forgotPassword: "auth/forgotPassword",
      forgotPasswordVerification: "auth/forgotPasswordVerification",
      resetPassword: "auth/resetPassword",
    },
    organization: {
      list: "organization/organizationList",
      create: "organization/add",
      edit: "organization/",
      details: "organization/",
      assignedList: "organization/assignOrganizationList",
      unAssignedList: "organization/unassignOrganizationList",
      assign: "organization/assignOrganization/",
    },
    users: {
      list: "users/getAll",
      details: "users/",
    },
    setting: {
      list: "settings",
      edit: "settings",
      resetVideoCount: "videoProgress/reset-video-count"
    },
    sync: {
      video: "video/syncAWSVideo",
    },
    member: {
      list: "admin/getAllUsers",
      create: "admin/createUser",
      edit: "admin/updateUser/",
      delete: "admin/",
      details: "admin/",
    },
    course: {
      list: "course/courseList",
      create: "course/add",
      delete: "course/",
      edit: "course/",
      details: "course/",
      assign: "course/assignCourse/",
      unAssign: "course/unassign",
      assignedList: "course/assignedCourseList",
      unAssignedList: "course/unassignedCourseList",
    },
    section: {
      list: "courseSection/list",
      create: "courseSection/add",
      delete: "courseSection/",
      edit: "courseSection/",
      details: "courseSection/",
    },
    video: {
      allList: "video/list",
      list: "courseVideo/list",
      create: "courseVideo/add",
      delete: "courseVideo/",
      edit: "courseVideo/",
      details: "courseVideo/",

    },
  },
  learner: {
    users: {
      list: "users/",
      edit: "users/",
    },
    auth: {
      resetPass: "auth/resetPassword",
    },
    statistics: {
      list: "courseProgress/home-page/statistics",
      details: "courseProgress/",
    },
    course: {
      notLearningList: "courseProgress/non-learning-courses",
      learningList: "courseProgress/learning-courses",
      startLearning: "videoProgress/start-learning",
      allList: "course/courseList",
      asignCourse: "videoProgress/start-learn",
    },
    section: {
      list: "sectionProgress/list",
      details: "sectionProgress/",
      detail: "sectionProgress/statistics/",
    },
    video: {
      list: "videoProgress/list",
      listDetails: "videoProgress/",
      watchHistory: "videoProgress/add",
      videoQuestionAnswer: "videoQuestionAnswer",
      skipVideo: "videoProgress/video-watch/"
    },
  },
  trainer: {
    statistics: {
      list: "courseProgress/statistics",
      details: "courseProgress/",
    },
    course: {
      allList: "courseProgress/learning-courses/",
      allAssignList: "course/courseList",
      allStatistic: "users/statistics/"
    },
    section: {
      list: "sectionProgress/list",
      details: "sectionProgress/",
    },
    video: {
      list: "videoProgress/user-video-list/",
      details: "videoProgress/",
    },
  },
};

// EXPORT API ROUTES OBJECT DEFINE
export { API };
