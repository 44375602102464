import { API } from "../../config/api/Index";
import Cookies from "js-cookie";
import { message } from "antd";
import { getItem, setItem } from "../../utility/localStorageControl";
import { DataService } from "../../config/DataService";

// LOGIN API CALL
export const login = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.admin.auth.login, payloads);
      if (loggedIn.data.status) {
        console.log("loggedIn.data.data--------->", loggedIn.data.data);

        Cookies.set("logedIn");
        setItem("userDetails", loggedIn.data.data);
        setItem("access_token", loggedIn.data.data.token);
        if (!getItem("selectedLanguage")) {
          setItem("selectedLanguage", "fr");
        }
        dispatch({
          type: API.admin.auth.login,
          login: true,
          userDetails: loggedIn.data.data,
        });
        message.success(loggedIn.data.message);
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// UPDATE PROFILE API CALL
export const updateProfile = (payloads) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.put(
        API.admin.auth.update + getItem("userDetails")?._id,
        payloads
      );
      if (loggedIn.data.status) {

        setItem("userDetails", {
          ...loggedIn.data.data,
          access_token: loggedIn.data.data.token,
        });
        dispatch({
          type: API.admin.auth.login,
          login: true,
          userDetails: {
            ...loggedIn.data.data,
            access_token: loggedIn.data.data.token,
          },
        });
        message.success(loggedIn.data.message);
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

// LOG OUT FUNCTION
export const logOut = () => {
  return async (dispatch) => {
    try {
      Cookies.remove("logedIn");
      let selectedLanguage = getItem("selectedLanguage");
      localStorage.clear();
      setItem("selectedLanguage", selectedLanguage);
      dispatch({
        type: API.admin.auth.login,
        userDetails: {},
        login: false,
      });
      message.success("Logout Successfully");
      return true;
    } catch (err) {
      return false;
    }
  };
};

// ASYNC VIDEO CALL
export const asyncAwsVideo = () => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.admin.sync.video);
      if (loggedIn.data.status) {
        message.success(loggedIn.data.message);
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const forgotPassword = (payload) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(
        API.admin.auth.forgotPassword,
        payload
      );
      if (loggedIn.data.status) {
        message.success(loggedIn.data.message);
        return loggedIn.data.data;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const otpVerification = (payload) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(
        API.admin.auth.forgotPasswordVerification,
        payload
      );
      if (loggedIn.data.status) {
        message.success(loggedIn.data.message);
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export const resetPassword = (payload) => {
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(
        API.admin.auth.resetPassword,
        payload
      );
      if (loggedIn.data.status) {
        message.success(loggedIn.data.message);
        return true;
      } else {
        message.error(loggedIn.data.message);
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
