import { UploadOutlined } from "@ant-design/icons";
import {
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Popover,
  Row,
  message
} from "antd";
import AOS from "aos";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  onUploadImageValidation
} from "../../../../../config/CommonFuntion";
import {
  memberCreate,
  memberDelete,
  memberEdit,
  memberList,
} from "../../../../../redux/admin/members/action";
import styles from "./style.module.css";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { allUserList } from "../../../../../redux/admin/members/action";
import { getItem } from "../../../../../utility/localStorageControl";

// DEFINE MAIN FUNCTION
const Learners = () => {
  // DEFINE ALL STATES
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [registerModal, setRegisterModal] = useState(false);
  const [registerModalType, setRegisterModalType] = useState("");
  const [userCurrentPage, setUserCurrentPage] = useState(1);
  const [userPageSize, setUserPageSize] = useState(10);
  const [userSearchData, setUserSearchData] = useState("");
  const [loading, setLoading] = useState(true);
  const [memberModal, setMemberModal] = useState(false);
  const uploadImageRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [memberModalType, setMemberModalType] = useState("");

  // DEFINE USEEFFECT
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  let listOfMember = useSelector((state) => state.member.usersList);
  let countsOfMember = useSelector((state) => state.member.usersListCounts);

  const onMemberFormSubmit = async (values) => {
    setModalLoading(true);
    let formData = new FormData();
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("position", values.position);
    if (memberModalType == "Create") {
      formData.append("role", "learner");
      formData.append("email", values.email);
      formData.append("profilePic", uploadedFile);
    } else {
      if (memberModalType == "Edit") {
        formData.append("profilePic", uploadedFile);
      }
    }
    if (memberModalType == "Edit") {
      let result = await dispatch(
        memberEdit(formData, selectedData._id, loadingPercentage)
      );
      if (result) {
        setUploadedFile(null);
        setMemberModal(false);
        form.resetFields();
        setMemberModalType("");
        setSelectedData(null);
        setLoading(true);
        userList(userCurrentPage, userPageSize, userSearchData);
      } else {
        setModalLoading(false);
      }
    } else if (memberModalType == "Create") {
      let result = await dispatch(memberCreate(formData, loadingPercentage));
      if (result) {
        setUploadedFile(null);
        setMemberModal(false);
        form.resetFields();
        setMemberModalType("");
        setSelectedData(null);
        setLoading(true);
        userList(userCurrentPage, userPageSize, userSearchData);
      } else {
        setModalLoading(false);
      }
    } else {
    }
  };

  // DEFINE FUNCTION FOR UPLOAD IMAGE
  const onUploadImageChange = (e) => {
    let result = onUploadImageValidation(e);
    if (result) {
      setUploadedFile(e.target.files[0]);
    }
  };
  // DEFINE FUNCTION FOR USER LIST
  const list = async (page, current, search, selectedorganization, role) => {
    await dispatch(
      memberList(
        page,
        current,
        search,
        selectedorganization,
        role == "all" ? "" : role
      )
    );
    setModalLoading(false);
    setLoading(false);
  };
  // DEFINE FUNCTION FOR USER LIST DATA
  const userList = async (page, current, search) => {
    await dispatch(
      allUserList(
        page,
        current,
        search,
        getItem("userDetails")?.organization?._id
      )
    );
    setLoading(false);
  };

  // DEFINE CREATE AND UPDATE API LOADING PERCENTAGE
  const loadingPercentage = (val) => {
    if (val == 100) {
      setModalLoading(false);
    }
  };
  // REGISTER FORM SUBMIT FUNCTION DEFINE
  const onFinish = async (values) => {
    setRegisterModal(false);
    form.resetFields();
    setRegisterModalType("");
  };
  // DEFINE FUNCTION FOR USER TABLE PAGE CHANGE
  const onUserPageChange = async (page, current) => {
    setUserCurrentPage(page);
    setUserPageSize(current);
    setLoading(true);
    userList(page, current, userSearchData);
  };

  // DEFINE USEEFFECT WITH USER SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      userList(userCurrentPage, userPageSize, userSearchData);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [userSearchData]);

  // REGISTER FORM EDIT OR DELETE BUTTON CLICK FUNCTION DEFINE
  const onRegistrationActionClick = (type, value) => {
    if (type == "edit") {
      setRegisterModal(true);
      setRegisterModalType("edit");
      form.setFieldsValue({
        linkName: value.name,
        welcomeText: "Welcome",
      });
    } else {
    }
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {/* DEFINE MODAL FOR USER CREATE & EDIT */}
      <Modal
        className={styles.antModel}
        title={`${t(memberModalType)} ${t("Member")}`}
        centered
        open={memberModal}
        okText={t("Save")}
        onOk={() => {
          if (!modalLoading) {
            form.submit();
          }
        }}
        cancelText={t("Cancel")}
        onCancel={() => {
          if (!modalLoading) {
            form.resetFields();
            setMemberModal(false);
            setMemberModalType("");
            setUploadedFile(null);
            setSelectedData(null);
          }
        }}
        confirmLoading={modalLoading}
      >
        <Form layout="vertical" form={form} onFinish={onMemberFormSubmit}>
          <Row gutter={16}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={t("First Name")}
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t("Please enter member first name!"),
                  },
                ]}
              >
                <Input
                  placeholder={t("Enter member first name")}
                  style={{ height: "45px" }}
                />
              </Form.Item>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={t("Last Name")}
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t("Please enter member last name!"),
                  },
                ]}
              >
                <Input
                  placeholder={t("Enter member last name")}
                  style={{ height: "45px" }}
                />
              </Form.Item>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item name="image" label={t("Image")}>
                <div className="orgUploadedIMG">
                  <div
                    style={{
                      width: uploadedFile ? "140px" : "100%",
                      fontSize: uploadedFile ? "12px" : "16px",
                    }}
                    onClick={() => {
                      uploadImageRef.current.click();
                    }}
                  >
                    <UploadOutlined
                      style={{
                        fontSize: uploadedFile ? "16px" : "20px",
                      }}
                    />{" "}
                    {t("Click to Upload")}
                  </div>
                  {uploadedFile ? (
                    <>
                      {" "}
                      <img
                        src={
                          typeof uploadedFile == "string"
                            ? uploadedFile
                            : URL.createObjectURL(uploadedFile)
                        }
                      />
                      <label
                        onClick={() => {
                          setUploadedFile(null);
                          form.resetFields(["image"]);
                        }}
                      >
                        x
                      </label>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <input
                  type="file"
                  id="file"
                  ref={uploadImageRef}
                  style={{ display: "none" }}
                  onChange={onUploadImageChange.bind(this)}
                />
              </Form.Item>
            </Col>
            {/* <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={t("Role")}
                name="role"
                rules={[
                  {
                    required: true,
                    message: t("Please select member role!"),
                  },
                ]}
                className="memberRole"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder={t("Select member role")}
                  style={{ height: "45px" }}
                  disabled={memberModalType == "Edit" ? true : false}
                  options={[
                    {
                      value: "learner",
                      label: t("Learner"),
                    },
                    {
                      value: "trainer",
                      label: t("Trainer"),
                    },
                  ]}
                />
              </Form.Item>
            </Col> */}
          </Row>
          <Form.Item
            label={t("Email")}
            name="email"
            rules={[
              {
                required: true,
                message: t("Please enter member email!"),
              },
              {
                type: "email",
                message: t("Please enter a valid email!"),
              },
            ]}
          >
            <Input
              placeholder={t("Enter member email")}
              disabled={memberModalType == "Edit" ? true : false}
              style={{
                height: "45px",
                color: "#8c8c8c",
                backgroundColor: "white",
              }}
            />
          </Form.Item>

          <Form.Item label={t("Position")} name="position">
            <Input
              placeholder={t("Member current position")}
              style={{ height: "45px" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={`${registerModalType == "edit" ? t("Edit") : t("Create")
          } ${"a registration link"}`}
        centered
        open={registerModal}
        okText={`${registerModalType == "edit" ? t("Edit") : t("Create")} ${t(
          "link"
        )}`}
        onOk={() => {
          form.submit();
        }}
        cancelText={t("Cancel")}
        onCancel={() => {
          setRegisterModal(false);
          form.resetFields();
          setRegisterModalType("");
        }}
      >
        <div className={styles.registerModalData}>
          <div className={styles.image}>
            {" "}
            <label>
              <FeatherIcon icon="box" color="white" size={36} />
            </label>
          </div>
          <div className={styles.details}>
            <label>{getItem("userDetails")?.organization.name}</label>
            <label>7 {t("Experiences")}</label>
          </div>
        </div>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            label={t("Link Name")}
            name="linkName"
            rules={[
              {
                required: true,
                message: t("Please enter your link name!"),
              },
            ]}
          >
            <Input
              placeholder={t("enter your link name")}
              style={{ height: "45px" }}
            />
          </Form.Item>
          <Form.Item label={t("Welcome text")} name="welcomeText">
            <Input
              placeholder={t("enter your welcome text")}
              style={{ height: "45px" }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <div className={styles.filterContent}>
        <Row gutter={12}>
          <Col xxl={9} xl={9} lg={10} md={13} sm={16} xs={24}>
            <div className={styles.searchData} data-aos="fade-right">
              <span>
                <FeatherIcon icon="search" size={24} />
                <input
                  value={userSearchData}
                  onChange={(event) => {
                    setUserSearchData(event.target.value);
                  }}
                  placeholder={t("Search for a workspace...")}
                />
                {userSearchData == "" ? (
                  ""
                ) : (
                  <label>
                    <FeatherIcon
                      onClick={() => {
                        setUserSearchData("");
                      }}
                      icon="x"
                      size={22}
                    />
                  </label>
                )}
              </span>
            </div>
          </Col>
          <Col xxl={13} xl={13} lg={12} md={8} sm={5} xs={24}>
            {/* <div className={styles.filterData} data-aos="fade-left">
              <div className={styles.filterIcone}>
                {" "}
                <FeatherIcon icon="user-plus" fill="white" size={21} />
                <span>{t("Invite Users")}</span>
              </div>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      icon: (
                        <FeatherIcon icon="user-plus" fill="white" size={18} />
                      ),
                      label: t("Invite Users"),
                    },
                    {
                      key: "2",
                      onClick: function () {
                        setRegisterModal(true);
                        setRegisterModalType("create");
                      },
                      icon: (
                        <FeatherIcon icon="link-2" fill="white" size={18} />
                      ),
                      label: t("Create a registration number"),
                    },
                  ],
                }}
                placement="bottomRight"
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <div className={styles.filterMenu}>
                  <svg
                    fill="white"
                    height="16px"
                    width="18px"
                    version="1.1"
                    id="Layer_1"
                    viewBox="0 0 386.257 386.257"
                  >
                    <polygon points="0,96.879 193.129,289.379 386.257,96.879 " />
                  </svg>
                </div>
              </Dropdown>
            </div> */}
          </Col>
          <Col xxl={2} xl={2} lg={2} md={3} sm={3} xs={24}>
            <button
              className="add-btn"
              onClick={() => {
                setMemberModal(true);
                setMemberModalType("Create");
              }}
            >
              {t("Add")} <FeatherIcon icon="plus" size={18} />
            </button>
          </Col>
        </Row>
      </div>

      <div className={styles.UserListingComponent} data-aos="fade-right">
        <MembersTableComponent
          memberData={listOfMember}
          userPageSize={userPageSize}
          userSearchData={userSearchData}
          userCurrentPage={userCurrentPage}
          onUserPageChange={onUserPageChange}
          countsOfMember={countsOfMember}
          setIsMemberModal={setMemberModal}
          isSelectedData={selectedData}
          setIsSelectedData={setSelectedData}
          setIsMemberModalType={setMemberModalType}
          setIsUploadedFile={setUploadedFile}
          isform={form}
        />
        {/* <Tabs
          className={styles.UserTabComponent}
          defaultActiveKey="2"
          items={[
            {
              key: 1,
              label: `${t("Members")}`,
              children: (
                <MembersTableComponent
                  memberData={listOfMember}
                  userPageSize={userPageSize}
                  userCurrentPage={userCurrentPage}
                  onUserPageChange={onUserPageChange}
                  countsOfMember={countsOfMember}
                />
              ),
              icon: (
                <FeatherIcon
                  icon="user-check"
                  size={22}
                  style={{ marginBottom: "-5px" }}
                />
              ),
            },
            {
              key: 2,
              label: `${t("Pending Invitations")} (2)`,
              children: (
                <PendingInvitationTableComponent
                  pendingInvitationData={pendingInvitationData}
                />
              ),
              icon: (
                <FeatherIcon
                  icon="alert-circle"
                  size={22}
                  style={{ marginBottom: "-5px" }}
                />
              ),
            },
            {
              key: 3,
              label: `${t("Regitration links")} (1)`,
              children: (
                <RegistrationLinkTableComponent
                  registrationLinkData={registrationLinkData}
                  onRegistrationActionClick={onRegistrationActionClick}
                />
              ),
              icon: (
                <FeatherIcon
                  icon="link-2"
                  size={22}
                  style={{ marginBottom: "-5px" }}
                />
              ),
            },
          ]}
        /> */}
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Learners;

// DEFINE MEMBERS TABLE COMPONENT
const MembersTableComponent = ({
  memberData,
  userPageSize,
  userCurrentPage,
  userSearchData,
  onUserPageChange,
  countsOfMember,
  setIsMemberModal,
  isSelectedData,
  setIsSelectedData,
  setIsMemberModalType,
  setIsUploadedFile,
  isform,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [memberDeleteModal, setMemberDeleteModal] = useState(false);

  const userList = async (page, current, search) => {
    await dispatch(
      allUserList(
        page,
        current,
        search,
        getItem("userDetails")?.organization?._id
      )
    );
    setLoading(false);
  };

  const deleteMember = async () => {
    let result = await dispatch(memberDelete(isSelectedData._id));
    if (result) {
      setMemberDeleteModal(false);
      setIsSelectedData(null);
      userList(userCurrentPage, userPageSize, userSearchData);
    } else {
      console.log("Error");
    }
  };
  return (
    <>
      <div className={styles.membersTableComponent}>
        <div className={styles.tableHeader}>
          <span>{t("Name")}</span>
          <span>{t("Roles")}</span>
          <span>{t("Position")}</span>
          <span>{t("Status")}</span>
          <span>{t("Action")}</span>
        </div>
        <div className={styles.tableBodyComponent}>
          {memberData?.map((value, index) => {
            return (
              <>
                <div key={index} className={styles.tabelData}>
                  <span>
                    <div className={styles.container}>
                      {value?.profilePic ? (
                        <div className={styles.detailsImagePic}>
                          <img src={value?.profilePic} alt="profile Image" />
                        </div>
                      ) : (
                        <div className={styles.image}>
                          {`${value.name.split(" ")[0].charAt(0)}${value.name.split(" ")[1]
                            ? value.name.split(" ")[1].charAt(0)
                            : ""
                            }`}
                        </div>
                      )}
                      <div className={styles.details}>
                        <div className={styles.name}>
                          {value.firstName} {value.lastName}
                        </div>
                        <div className={styles.description}>{value.email}</div>
                      </div>
                    </div>
                  </span>
                  <span>{value.role}</span>
                  <span>{value.position ? value.position : "-"}</span>
                  <span>{value.status == "verified" ? t("verified") : value.status == "not verified" ? t("not verified") : "-"}</span>
                  <span>
                    <FeatherIcon
                      icon={"eye"}
                      onClick={() => {
                        navigate(`/company/learner/${value._id}`);
                      }}
                      style={{ cursor: "pointer" }}
                      color="rgb(11, 35, 34)"
                      size={22}
                    />
                    <FeatherIcon
                      icon={"edit"}
                      onClick={() => {
                        setIsMemberModal(true);
                        setIsMemberModalType("Edit");
                        setIsSelectedData(value);
                        setIsUploadedFile(
                          value.profilePic == ""
                            ? "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                            : value.profilePic
                        );
                        isform.setFieldsValue({
                          firstName: value.firstName,
                          lastName: value.lastName,
                          position: value.position,
                          profilePic: value.profilePic,
                          role: value.role,
                          email: value.email,
                          organization: value?.organization?._id,
                        });
                      }}
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                      color="rgb(11, 35, 34)"
                      size={22}
                    />
                    {value && value?.role == "learner" ? (
                      <FeatherIcon
                        icon={"trash"}
                        onClick={() => {
                          setMemberDeleteModal(true);
                          setIsSelectedData(value);
                        }}
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        color="rgb(11, 35, 34)"
                        size={22}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </>
            );
          })}
        </div>
        <div className={styles.pagination}>
          <Pagination
            total={countsOfMember?.totalCount}
            showSizeChanger
            userPageSizeOptions={[10, 20, 50, 100]}
            defaultuserPageSize={userPageSize}
            defaultCurrent={userCurrentPage}
            onChange={onUserPageChange}
          />
        </div>
      </div>
      <Modal
        centered
        open={memberDeleteModal}
        okText={t("Delete")}
        onOk={() => {
          deleteMember();
        }}
        cancelText={t("Cancel")}
        onCancel={() => {
          setMemberDeleteModal(false);
        }}
      >
        <Row gutter={16}>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <div
              className="d-flex flex-column text-center"
              style={{ marginTop: "30px" }}
            >
              <FeatherIcon
                icon={"alert-circle"}
                style={{ cursor: "pointer" }}
                color="#FCA12F"
                size={80}
              />
              <h2>{t("Are you sure you want to delete?")}</h2>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

// DEFINE PENDING INVITATION TABLE COMPONENT
const PendingInvitationTableComponent = ({ pendingInvitationData }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.pmembersTableComponent}>
        <div className={styles.ptableHeader}>
          <span>{t("Identity")}</span>
          <span>{t("Authentication")}</span>
          <span>{t("Expires in")}</span>
          <span>{t("Role")}</span>
        </div>
        <div className={styles.ptableBodyComponent}>
          {pendingInvitationData.map((value, index) => {
            return (
              <>
                <div key={index} className={styles.ptabelData}>
                  <span>
                    <div className={styles.pcontainer}>
                      <div className={styles.pimage}>
                        {value.email.split(" ")[0].split("")[0]}
                      </div>
                      <div
                        className={styles.pdetails}
                        style={{ alignItems: "center" }}
                      >
                        <div className={styles.pname}>{value.email}</div>
                      </div>
                    </div>
                  </span>
                  <span>
                    <FeatherIcon icon="key" size={22} />
                    {value.authentication}
                  </span>
                  <span style={{ color: "black" }}>
                    {" "}
                    <FeatherIcon icon="layout" size={22} />{" "}
                    {moment(value.expiresDate, "DD-MM-YYYY").fromNow()}
                  </span>
                  <span
                    style={{
                      color:
                        value.role == "Learner"
                          ? "rgb(93 160 161)"
                          : "rgb(235 58 80)",
                    }}
                  >
                    <label
                      style={{
                        background:
                          value.role == "Learner"
                            ? "rgb(221 238 240)"
                            : "rgb(255 239 240)",
                      }}
                    >
                      <FeatherIcon icon="refresh-cw" size={22} />
                      {value.role}
                    </label>
                  </span>
                </div>
              </>
            );
          })}
        </div>
        <div className={styles.pagination}>
          <Pagination total={50} showSizeChanger />
        </div>
      </div>
    </>
  );
};

// DEFINE REGISTRATION LINK TABLE COMPONENT
const RegistrationLinkTableComponent = ({
  registrationLinkData,
  onRegistrationActionClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.membersTableComponent}>
        <div className={styles.registrationTableHeader}>
          <span>{t("Name")}</span>
          <span>{t("Link")}</span>
          <span>{t("Role")}</span>
          <span>{t("Actions")}</span>
        </div>
        <div className={styles.tableBodyComponent}>
          {registrationLinkData.map((value, index) => {
            return (
              <>
                <div key={index} className={styles.registrationTabelData}>
                  <span>
                    <div className={styles.container}>
                      <div className={styles.details}>
                        <div className={styles.name}>{value.name}</div>
                      </div>
                    </div>
                  </span>
                  <Popover content={<div>{t("Click & Copy Link!")}</div>}>
                    <span
                      onClick={() => {
                        navigator.clipboard.writeText(value.link);
                        message.success(t("Link copied!"));
                      }}
                    >
                      {value.link}{" "}
                      <svg
                        fill="#000000"
                        width="28px"
                        height="28px"
                        viewBox="0 0 24 24"
                      >
                        <path d="M21,10V20a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V10a1,1,0,0,1,1-1H20A1,1,0,0,1,21,10ZM6,14H5V5h9V6a1,1,0,0,0,2,0V4a1,1,0,0,0-1-1H4A1,1,0,0,0,3,4V15a1,1,0,0,0,1,1H6a1,1,0,0,0,0-2Z" />
                      </svg>
                    </span>
                  </Popover>

                  <span>
                    <label>{value.role}</label>
                  </span>
                  <span>
                    <label
                      onClick={() => {
                        onRegistrationActionClick("edit", value);
                      }}
                    >
                      {" "}
                      <FeatherIcon icon="edit-2" size={22} />
                    </label>
                    <label
                      onClick={() => {
                        onRegistrationActionClick("delete", value);
                      }}
                    >
                      {" "}
                      <FeatherIcon icon="trash" size={22} />
                    </label>
                  </span>
                </div>
              </>
            );
          })}
        </div>
        <div className={styles.pagination}>
          <Pagination total={50} showSizeChanger />
        </div>
      </div>
    </>
  );
};
