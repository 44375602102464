import { combineReducers } from "redux";
import AuthReducer from "./authentication/reducers";
import OrganizationReducer from "./admin/organization/reducers";
import MemberReducer from "./admin/members/reducers";
import CourseReducer from "./admin/course/reducers";
import SectionReducer from "./admin/section/reducers";
import VideoReducer from "./admin/videos/reducers";
import LearnerCourseReducer from "./user/learner/course/reducers";
import LearnerSectionReducer from "./user/learner/section/reducers";
import LearnerVideoReducer from "./user/learner/videos/reducers";
import DashboardReducer from "./admin/dashboard/reducers";
import StatisticReducer from "./user/learner/statistics/reducers";
import usersReducer from "./user/learner/users/reducers";
import SettingsReducer from "./admin/setting/reducers";
const rootReducers = combineReducers({
  auth: AuthReducer,
  dashboard: DashboardReducer,
  organization: OrganizationReducer,
  member: MemberReducer,
  course: CourseReducer,
  section: SectionReducer,
  video: VideoReducer,
  learnerCourse: LearnerCourseReducer,
  learnerSection: LearnerSectionReducer,
  learnerVideo: LearnerVideoReducer,
  statistic: StatisticReducer,
  userDetails: usersReducer,
  setting: SettingsReducer
});

export default rootReducers;
