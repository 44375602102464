import React from "react";
import { Menu } from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/user/Logo.png";

// DEFINE MAIN FUNCTION
const Sidenav = ({ color }) => {
  // DEFINE ALL STATES
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const page = pathname.replace("/", "");

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className="brand">
        <img
          src={logo}
          onClick={() => {
            navigate("/dashboard");
          }}
          alt=""
        />
        <span
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Nexa
        </span>
      </div>
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                key={0}
              >
                <path
                  d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
                  fill={color}
                ></path>
                <path
                  d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
                  fill={color}
                ></path>
                <path
                  d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
                  fill={color}
                ></path>
              </svg>
            </span>
            <span className="label">{t("Dashboard")}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/organization">
            <span
              className="icon"
              style={{
                background: page === "organization" ? color : "",
              }}
            >
              <svg
                version="1.1"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0 0 256 256"
                enableBackground="new 0 0 256 256"
              >
                <g>
                  <g>
                    <path fill={color} d="M123.1,59.1h28.4V65h-28.4V59.1z" />
                    <path
                      fill={color}
                      d="M141.6,168.3h14.2v17.6h-14.2V168.3z"
                    />
                    <path fill={color} d="M87.8,95.9h28.4v5.8H87.8V95.9z" />
                    <path
                      fill={color}
                      d="M80.1,107.6v78.3h37.2v-78.3H81.9H80.1z M86,119h25.2v5.9H86V119z M86,135.2h25.2v5.9H86V135.2z M86,151.9h25.2v5.9H86V151.9z M86,168.6h25.2v5.9H86V168.6z"
                    />
                    <path
                      fill={color}
                      d="M123.1,70.9v30.8v84.2h12.6v-23.5h26v23.5h14.2v-115h-18.6H123.1z M169.6,131.6h-40.2v-5.9h40.2V131.6z M169.6,112.2h-40.2v-5.9h40.2V112.2z M169.6,92.8h-40.2v-5.9h40.2V92.8z"
                    />
                    <path
                      fill={color}
                      d="M128,10C62.8,10,10,62.8,10,128c0,65.2,52.8,118,118,118c65.2,0,118-52.8,118-118C246,62.8,193.2,10,128,10z M206.7,191.7h-24.8h-20.1h-26h-12.6h-5.9H74.2H49.3v-5.9h24.8v-84.2h7.7V90h35.3V70.9V65V53.2h40.2V65h24.5v120.9h24.8L206.7,191.7L206.7,191.7z"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <span className="label">{t("Organization")}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/members">
            <span
              className="icon"
              style={{
                background: page === "members" ? color : "",
              }}
            >
              <svg
                className="svg-icon"
                width="20"
                height="20"
                viewBox="0 0 1024 1024"
                version="1.1"
              >
                <path d="M512.476 648.247c-170.169 0-308.118-136.411-308.118-304.681 0-168.271 137.949-304.681 308.118-304.681 170.169 0 308.119 136.411 308.119 304.681C820.594 511.837 682.645 648.247 512.476 648.247L512.476 648.247zM512.476 100.186c-135.713 0-246.12 109.178-246.12 243.381 0 134.202 110.407 243.381 246.12 243.381 135.719 0 246.126-109.179 246.126-243.381C758.602 209.364 648.195 100.186 512.476 100.186L512.476 100.186zM935.867 985.115l-26.164 0c-9.648 0-17.779-6.941-19.384-16.35-2.646-15.426-6.277-30.52-11.142-44.95-24.769-87.686-81.337-164.13-159.104-214.266-63.232 35.203-134.235 53.64-207.597 53.64-73.555 0-144.73-18.537-208.084-53.922-78 50.131-134.75 126.68-159.564 214.549 0 0-4.893 18.172-11.795 46.4-2.136 8.723-10.035 14.9-19.112 14.9L88.133 985.116c-9.415 0-16.693-8.214-15.47-17.452C91.698 824.084 181.099 702.474 305.51 637.615c58.682 40.472 129.996 64.267 206.966 64.267 76.799 0 147.968-23.684 206.584-63.991 124.123 64.932 213.281 186.403 232.277 329.772C952.56 976.901 945.287 985.115 935.867 985.115L935.867 985.115z" />
              </svg>
            </span>
            <span className="label">{t("Members")}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/course">
            <span
              className="icon"
              style={{
                background: page === "course" ? color : "",
              }}
            >
              <svg
                version="1.0"
                width="20"
                height="20"
                viewBox="0 0 1280.000000 1280.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                  fill="white"
                  stroke="none"
                >
                  <path d="M6120 12794 c-710 -48 -1205 -142 -1790 -342 -674 -231 -1331 -585 -1885 -1017 -302 -236 -722 -639 -952 -915 -851 -1021 -1339 -2202 -1469 -3555 -22 -224 -25 -826 -6 -1045 50 -570 150 -1054 320 -1560 398 -1179 1112 -2203 2067 -2965 920 -733 2023 -1197 3200 -1344 311 -39 425 -45 800 -45 372 0 552 11 840 50 1210 163 2339 663 3295 1459 195 162 608 574 767 765 851 1022 1339 2202 1469 3555 22 224 25 826 6 1045 -39 446 -108 834 -217 1225 -250 898 -677 1711 -1284 2440 -158 190 -572 604 -761 762 -1019 849 -2214 1343 -3550 1469 -154 14 -724 26 -850 18z m717 -1279 c787 -72 1483 -295 2158 -688 857 -500 1570 -1266 2005 -2152 275 -559 429 -1085 506 -1728 25 -202 30 -755 10 -973 -62 -673 -223 -1253 -511 -1839 -497 -1010 -1329 -1843 -2330 -2335 -559 -275 -1085 -429 -1728 -506 -202 -25 -755 -30 -973 -10 -799 73 -1491 294 -2169 689 -1158 676 -2032 1823 -2366 3102 -117 452 -163 812 -162 1290 0 532 67 993 214 1482 316 1049 947 1969 1803 2632 359 278 757 508 1166 674 457 186 967 312 1440 356 63 6 131 13 150 14 111 11 642 5 787 -8z"></path>
                  <path d="M4980 8648 c-60 -31 -99 -92 -141 -220 l-34 -103 2 -640 c1 -352 7 -1234 13 -1960 11 -1226 13 -1326 30 -1400 67 -289 179 -342 506 -237 12 4 774 441 1694 972 1567 904 1677 969 1740 1032 92 91 134 174 128 255 -6 81 -41 146 -123 228 -67 67 -141 111 -1760 1051 -1186 689 -1709 988 -1755 1002 -151 48 -234 53 -300 20z"></path>
                </g>
              </svg>
            </span>
            <span className="label">{t("Course")}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/setting">
            <span
              className="icon"
              style={{
                background: page === "setting" ? color : "",
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 32 32" id="settings">
                <path fill="#d0d2d3" d="M29,13H26.58a10.8,10.8,0,0,0-1-2.36l1.71-1.71a1,1,0,0,0,0-1.42L24.49,4.69a1,1,0,0,0-1.42,0L21.36,6.4a10.8,10.8,0,0,0-2.36-1V3a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1V5.42a10.8,10.8,0,0,0-2.36,1L8.93,4.69a1,1,0,0,0-1.42,0L4.69,7.51a1,1,0,0,0,0,1.42L6.4,10.64a10.8,10.8,0,0,0-1,2.36H3a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H5.42a10.8,10.8,0,0,0,1,2.36L4.69,23.07a1,1,0,0,0,0,1.42l2.82,2.82a1,1,0,0,0,1.42,0l1.71-1.71a10.8,10.8,0,0,0,2.36,1V29a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V26.58a10.8,10.8,0,0,0,2.36-1l1.71,1.71a1,1,0,0,0,1.42,0l2.82-2.82a1,1,0,0,0,0-1.42L25.6,21.36a10.8,10.8,0,0,0,1-2.36H29a1,1,0,0,0,1-1V14A1,1,0,0,0,29,13ZM16,20a4,4,0,1,1,4-4A4,4,0,0,1,16,20Z"></path>
                <circle cx="0" cy="-10" r="4" fill="#58595b"></circle>
              </svg>
            </span>
            <span className="label">{t("Settings")}</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default Sidenav;
