const initState = {
  list: {},
  details: undefined,
};

const SettingsReducer = (state = initState, action) => {
  console.log("action", action);

  switch (action.type) {
    case "FETCH_SETTING_LIST":
      return {
        ...state,
        list: action.payload,
      };
    case "UPDATE_SETTING":
      return {
        ...state,
        list: { ...state.list, ...action.payload }, // Update state with new data
      };
    default:
      return state;
  }
};

export default SettingsReducer;
