import { message } from "antd";
import { API } from "../../../config/api/Index";
import { DataService } from "../../../config/DataService";

// GET SETTINGS LIST API CALL
export const SettingList = () => {
  return async (dispatch) => {
    try {
      const response = await DataService.get(API.admin.setting.list);
      console.log("response.data.data", response.data.data);

      if (response.data.status) {
        dispatch({
          type: "FETCH_SETTING_LIST", // Fix action type
          payload: response.data.data,
        });
        return response.data.data; // Return fetched data
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      message.error("Failed to fetch settings");
      return false;
    }
  };
};

// UPDATE SETTINGS API CALL
export const settingEdit = (payload) => {
  return async (dispatch) => {
    try {
      const response = await DataService.post(API.admin.setting.edit, payload);

      if (response.data.status) {
        dispatch({
          type: "UPDATE_SETTING",
          payload: payload,
        });
        message.success(response.data.message);
        return true;
      } else {
        message.error(response.data.message);
        return false;
      }
    } catch (err) {
      message.error("Failed to update settings");
      return false;
    }
  };
};
