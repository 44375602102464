import {
  Card,
  Col,
  Empty,
  Flex,
  Form,
  Modal,
  Pagination,
  Progress,
  Row,
  Select,
  Skeleton,
} from "antd";
import Meta from "antd/es/card/Meta";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.css";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Chart from "react-apexcharts";
import {
  allAssignLearnerCourseList,
  allAssignSubmit,
  allLearnerCourseList,
  allStatisticsList,
} from "../../../../redux/user/learner/course/action";
import { statisticsTrainerList } from "../../../../redux/user/learner/statistics/action";
import {
  getItem
} from "../../../../utility/localStorageControl";
const twoColors = {
  "0%": "#1D0F3F",
  "100%": "#A266E9"
};
// DEFINE MAIN FUNCTION
const AdminCompanyWorkSpace = () => {
  // DEFINE ALL STATES
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [courseModal, setCourseModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [listOfAssignCourses, setlistOfAssignCourses] = useState();
  const userId = window.location.pathname.split("/").pop();
  const [showReport, setShowReport] = useState(false);

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    detalsList();
    const delayDebounceFn = setTimeout(() => {
      coursesList(currentPage, pageSize, searchData);
      statisticsList();

    }, 1000);
    coursesAssignList();


    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  const detalsList = async () => {
    let orgId = getItem("userDetails")?.organization?._id;
    await dispatch(statisticsTrainerList(userId, orgId));
  };
  let detailsStatistics = useSelector(
    (state) => state.statistic.trainerDetails
  );
  // DEFINE ALL LISTS WITH REDUX
  let listOfCourses = useSelector(
    (state) => state.learnerCourse.allLearnerCourseList
  );
  let listOfStatistic = useSelector(
    (state) => state.learnerCourse.allStatisticsList
  );
  console.log("listOfAssignCourses====>", listOfAssignCourses);

  let countsOfCourses = useSelector(
    (state) => state.learnerCourse.allCourseListCounts
  );
  const chartOptions = {
    chart: {
      height: 450,
      type: "pie",
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        autoSelected: "zoom",
      },
      animations: {
        enabled: false,
      },
      events: {
        dataPointSelection: function (event, chartContext, config) {
          console.log(config.dataPointIndex + " " + config.seriesIndex);
        },
        click: function (event, chartContext, config) {
          console.log(config.dataPointIndex + " " + config.seriesIndex);
        },
      },
    },
    labels: ["Web", "VR"],
    colors: ["#C3E4DE", "#06a689"],
  };
  const radialOptions = {
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5,
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#444",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      colors: ["#06a689"],
      type: "gradient",
      gradient: {
        shade: "light",
        gradientToColors: ["#06a689"],
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: ["Average Results"],
  };
  const barOptions = {
    series: [
      {
        name: "sales",
        data: [
          { x: '2019/01/01', y: listOfStatistic?.overallSuccessRate },
          { x: '2019/01/01', y: listOfStatistic?.engagementStatistics?.contentCompletionRate },
          { x: '2019/04/01', y: listOfStatistic?.engagementStatistics?.comprehensionRate },
          { x: '2019/07/01', y: listOfStatistic?.engagementStatistics?.concentrationRate },
          { x: '2020/01/01', y: listOfStatistic?.engagementStatistics?.globalPerformanceIndicator },
          { x: '2020/04/01', y: listOfStatistic?.engagementStatistics?.moduleDifficultyRate },
        ]
      }
    ],


    plotOptions: {
      bar: {
        borderRadius: 20,
        borderRadiusApplication: "end",
        dataLabels: {
          position: "top",
        },
      },
    },
    chart: {
      type: 'bar',
      height: 380,
      animations: {
        enabled: false,
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
        }

      }
    },
    colors: ['#06a689', '#06a689', '#06a689', '#06a689', '#06a689', '#06a689'],
    xaxis: {
      type: 'category',
      categories: [t("Overall success rate"), t("Content completion rate"), t("Comprehension rate"), t("Concentration rate"), t("Overall performance indicator"), t("Module difficulty rate")],
      labels: {
        style: {
          fontSize: '10px',
          fontWeight: 700
        }
      }
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5,
      labels: {
        formatter: function (value) {
          return value + "%";
        }
      }
    },

    tooltip: {
      x: {
        formatter: function (val) {
          return val + " Sales";
        }
      }
    },

  };
  const reportRef = useRef(null);


  const downloadPDFF = async () => {
    setShowReport(true);

    setTimeout(async () => {
      if (!reportRef.current) return;

      const pdf = new jsPDF("p", "mm", "a4");
      // const pageHeight = pdf.internal.pageSize.height;
      const pageHeight = pdf.internal.pageSize.height;
      const imgWidth = 190;
      let yOffset = 10;
      let pageCount = 0;

      // Convert the full report div into a canvas
      const canvas = await html2canvas(reportRef.current, {
        scale: 2, // Improves quality
      });

      const imgData = canvas.toDataURL("image/png");
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      setShowReport(false);

      if (imgHeight < pageHeight) {
        // Single page case
        pdf.addImage(imgData, "PNG", 10, yOffset, imgWidth, imgHeight);
      } else {
        // Multi-page handling
        let remainingHeight = imgHeight;
        let position = 10;

        while (remainingHeight > 0) {
          let position = pageCount === 0 ? 10 : -285 * pageCount;

          pdf.addImage(
            imgData,
            "PNG",
            10,
            position,
            imgWidth,
            imgHeight
          );

          remainingHeight -= pageHeight - 20; // Adjusting for margins
          pageCount++; // Increment for next page

          if (remainingHeight > 0) {
            pdf.addPage();
          }
        }
      }

      pdf.save("Statistics.pdf");
    }, 1000);
  };
  const radialSeries = [parseFloat(listOfStatistic.overallSuccessRate)];
  const radialSeries0 = [parseFloat(listOfStatistic.satisfactionRate)];
  const radialSeries1 = [parseFloat(listOfStatistic?.engagementStatistics?.contentCompletionRate)];
  const radialSeries2 = [parseFloat(listOfStatistic?.engagementStatistics?.comprehensionRate)];
  const radialSeries3 = [parseFloat(listOfStatistic?.engagementStatistics?.concentrationRate)];
  const radialSeries4 = [parseFloat(listOfStatistic?.engagementStatistics?.globalPerformanceIndicator)];
  const radialSeries5 = [parseFloat(listOfStatistic?.engagementStatistics?.moduleDifficultyRate)];

  const chartSeries = [parseFloat(listOfStatistic?.session?.watchFromVr), parseFloat(listOfStatistic?.session?.watchFromWeb)];
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours ? hours : "0"}h : ${minutes ? minutes : "0"}m : ${seconds ? seconds : "0"}s`;
  };
  // DEFINE FUNCTION FOR COURSE LIST
  const coursesList = async (page, current, search) => {
    await dispatch(allLearnerCourseList(page, current, search, userId));
  };
  const statisticsList = async () => {
    await dispatch(allStatisticsList(userId));
  };
  const coursesAssignList = async () => {
    let orgId = getItem("userDetails")?.organization?._id;

    let res = await dispatch(allAssignLearnerCourseList(orgId));
    setlistOfAssignCourses(res);
  };
  const assignedCourseIds = new Set(listOfCourses.map((course) => course._id));
  console.log("listOfAssignCourses", listOfAssignCourses);

  const listOfUnAssignCourse =
    listOfAssignCourses &&
    listOfAssignCourses.map((course) => ({
      label: course.title,
      value: course._id,
      disabled: assignedCourseIds.has(course._id),
    }));
  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onPageChange = async (page, current) => {
    setCurrentPage(page);
    setPageSize(current);
    coursesList(page, current, searchData);
  };
  const onCourseFormSubmit = async (values) => {
    let payload = {
      courseId: values.courseId,
      userId: userId,
    };
    let result = await dispatch(allAssignSubmit(payload));
    if (result) {
      setCourseModal(false);
      form.resetFields();
      coursesList(currentPage, pageSize, "");
    }
  };
  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      {/* DEFINE MODAL FOR COURSE */}
      <Modal
        className={styles.antModel}
        title={` ${t("Assign Course")}`}
        centered
        open={courseModal}
        okText={t("Save")}
        onOk={() => {
          if (!modalLoading) {
            form.submit();
          }
        }}
        onCancel={() => {
          if (!modalLoading) {
            form.resetFields();
            setCourseModal(false);
            // setSelectedData(null);
          }
        }}
        confirmLoading={modalLoading}
      >
        <Form layout="vertical" form={form} onFinish={onCourseFormSubmit}>
          <Form.Item
            label={t("Course")}
            name="courseId"
            rules={[
              {
                required: true,
                message: t("Please select Course!"),
              },
            ]}
          >
            <Select
              showSearch
              mode="multiple"
              size="large"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder={t("select course")}
              style={{
                minHeight: "45px",
              }}
              options={listOfUnAssignCourse}
            />
          </Form.Item>
        </Form>
      </Modal>
      <button
        className="add-btn"
        style={{ marginBottom: "20px" }}
        onClick={() => {
          navigate(`/members`);
        }}
      >
        <FeatherIcon icon="chevron-left" size={18} /> {t("Back")}
      </button>
      <Row gutter={25} className="d-flex justify-content-center">
        <Col
          xxl={8}
          xl={8}
          lg={12}
          md={12}
          sm={24}
          xs={24}
          className={styles.cardDiv}
        >
          <div className={styles.courseCardImg}>
            <div className={styles.courseCardImageDiv}>
              {detailsStatistics?.user?.profilePic ? (
                <div className={styles.detailsImagePic}>
                  <img src={detailsStatistics?.user?.profilePic} alt="asd" />
                </div>
              ) : (
                <div className={styles.detailsImage}>
                  {detailsStatistics?.user?.name
                    ? `${detailsStatistics?.user?.name
                      .split(" ")[0]
                      .charAt(0)}${detailsStatistics?.user?.name.split(" ")[1]
                        ? detailsStatistics?.user?.name
                          .split(" ")[1]
                          .charAt(0)
                        : ""
                    }`
                    : ""}
                </div>
              )}
            </div>
            <div className={styles.courseImgTextDiv}>
              {detailsStatistics?.user?.name}
              <br />
              <div className={styles.queCardImgDiv}>{t("Administrator")}</div>

              <span>
                <i class="fa-regular fa-envelope"></i>
                {"  "}{" "}
                {detailsStatistics?.user?.email
                  ? detailsStatistics?.user?.email
                  : "-"}
              </span>
            </div>
            <div className={styles.queCardPosDiv}>
              <i class="fa-solid fa-user-tie"></i> {t("Position")}:{" "}
              {detailsStatistics?.user?.position
                ? detailsStatistics?.user?.position
                : "-"}
            </div>
          </div>
        </Col>
        <Col
          xxl={8}
          xl={8}
          lg={12}
          md={12}
          sm={24}
          xs={24}
          className={styles.cardDiv}
        >
          <div className={styles.courseCard}>
            <div className={styles.courseCardTextDiv}>
              <span>{t("Learning courses")}</span>
            </div>
            <div className={styles.courseCardProgressDiv}>
              <Flex gap="small" wrap>
                <Progress
                  className={styles.antProgress}
                  type="circle"
                  percent={detailsStatistics?.course?.completionPercentage}
                  strokeWidth={10}
                  strokeColor={twoColors}
                />
              </Flex>
            </div>
            <div className={styles.descmainDiv}>
              <div className={styles.courseCardDescDiv}>
                <i class="fa-solid fa-graduation-cap"></i> {t("Total Course")}:
                <span>{detailsStatistics?.course?.totalLearningCourse}</span>
              </div>
              <div className={styles.courseCardDesDiv}>
                <i class="fa-regular fa-circle-check"></i> {t("Completed")}:
                <span>{detailsStatistics?.course?.completedCourses}</span>
              </div>
            </div>
          </div>
        </Col>
        <Col
          xxl={8}
          xl={8}
          lg={12}
          md={24}
          sm={24}
          xs={24}
          className={styles.cardDiv2}
        >
          <div className={styles.courseCard}>
            <div className={styles.courseCardTextDiv}>
              <span>{t("Questions")}</span>
            </div>
            <div className={styles.courseCardProgressDiv}>
              <Flex gap="small" wrap>
                <Progress
                  className={styles.antProgress}
                  type="circle"
                  percent={detailsStatistics?.questions?.correctionRatio}
                  strokeWidth={10}
                  strokeColor={twoColors}
                />
              </Flex>
            </div>
            <div className={styles.descmainDiv}>
              <div className={styles.queCardDescDiv}>
                <i class="fa-solid fa-circle-question"></i>{" "}
                {t("Total Questions")}:
                <span> {detailsStatistics?.questions?.totalQuestions}</span>
              </div>
              <div className={styles.queCardDesDiv}>
                <i class="fa-regular fa-circle-right"></i> {t("Answered")}:
                <span> {detailsStatistics?.questions?.answeredQuestions}</span>
              </div>
              <div className={styles.queCardDeDiv}>
                <i class="fa-regular fa-circle-check"></i> {t("Correct Answer")}
                :<span> {detailsStatistics?.questions?.rightAnswers}</span>/{" "}
                {detailsStatistics?.questions?.answeredQuestions}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className={styles.headerComponent}>
        <span>{t("Courses List")}</span>
        <hr />
      </div>
      <div className={styles.filterContent}>
        <Row gutter={12}>
          <Col xxl={9} xl={9} lg={10} md={13} sm={16} xs={24}>
            <div className={styles.searchData}>
              <span>
                <FeatherIcon icon="search" size={24} />
                <input
                  value={searchData}
                  onChange={(event) => {
                    setSearchData(event.target.value);
                  }}
                  placeholder={t("Search for a workspace...")}
                />
                {searchData == "" ? (
                  ""
                ) : (
                  <label>
                    <FeatherIcon
                      onClick={() => {
                        setSearchData("");
                      }}
                      icon="x"
                      size={22}
                    />
                  </label>
                )}
              </span>
            </div>
          </Col>
          <Col xxl={7} xl={7} lg={10} md={6} sm={2} xs={24}>
          </Col>
          {/* <Col xxl={4} xl={4} lg={4} md={5} sm={6} xs={24}>
            <div>
              <button className="add-btn" onClick={downloadPDFF} >
                Download Statistics
              </button>
            </div>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={5} sm={6} xs={24}>
            <button
              className="add-btn"
              onClick={() => {
                setCourseModal(true);
                // setMemberModalType("Create");
              }}
            >
              {t("Assign Course")} <FeatherIcon icon="plus" size={18} />
            </button>
          </Col>{" "} */}
        </Row>
      </div>
      <div className={styles.latestVideoContent}>
        <Row gutter={25} className="criclebox tablespace mb-24 coursesCard">
          {listOfCourses ? (
            listOfCourses.length > 0 ? (
              listOfCourses.map((value, index) => {
                return (
                  <>
                    <Col
                      key={index}
                      xxl={8}
                      xl={8}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={24}
                    >
                      <Card
                        onClick={() => {
                          navigate(`/company/learner/${userId}/${value._id}`);
                        }}
                        hoverable
                        cover={
                          <img
                            alt=""
                            src={
                              value.image
                                ? value.image == ""
                                  ? "https://img-c.udemycdn.com/course/240x135/1362070_b9a1_2.jpg"
                                  : value.image
                                : "https://img-c.udemycdn.com/course/240x135/1362070_b9a1_2.jpg"
                            }
                          />
                        }
                      >
                        <Meta
                          title={value.title}
                          description={value.discription}
                        />
                        <Progress
                          className={styles.antProgress}
                          percent={value.completedPercentage}
                          size="small"
                          status="active"
                        />
                        <div className={styles.time_card}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-clock"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <polyline points="12 6 12 12 16 14"></polyline>
                          </svg>
                          <span>
                            {formatTime(Math.round(value.totalTimeSpend))}
                          </span>
                        </div>
                      </Card>
                    </Col>
                  </>
                );
              })
            ) : (
              <div className="no-data-component">
                <Empty />
              </div>
            )
          ) : (
            Array.from(Array(6).keys())
              .map((name) => ({ name }))
              .map((item, index) => {
                return (
                  <Col
                    key={index}
                    xxl={8}
                    xl={8}
                    lg={12}
                    md={12}
                    sm={12}
                    className="course-list-skeleton"
                    xs={24}
                  >
                    <Card
                      cover={
                        <Skeleton.Input
                          key={index}
                          active={true}
                          size={"large"}
                        />
                      }
                    >
                      <Meta title={""} description={""} />
                    </Card>
                  </Col>
                );
              })
          )}
        </Row>

        {listOfAssignCourses?.length > 0 ? (
          <div className="tabelsPagenation">
            <Pagination
              total={countsOfCourses && countsOfCourses.totalCount}
              showSizeChanger
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={pageSize}
              defaultCurrent={1}
              current={currentPage}
              onChange={onPageChange}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {showReport && (
        <section class="statistics" ref={reportRef} >
          <div class="data">
            <div class="container">
              <h1 class="mainTitle">{t("Training monitoring report")}</h1>
              <Row gutter={25} >
                <Col
                  xxl={17}
                  xl={17}
                  lg={17}
                  md={17}
                  sm={24}
                  xs={24}

                >
                  <div class="detailsmain">
                    <h2 >{t("Key performance indicators")}</h2>
                    <h2>{t("PARTICIPANT")}</h2>
                    <p>{t("Name / First name:")} <span>{listOfStatistic.firstName} {listOfStatistic.lastName}</span> </p>
                    <p>{t("Training start date:")} <span>{moment(listOfStatistic.trainingStartDate).format("DD-MM-YYYY")}</span></p>
                    <p>{t("Last connection date:")} <span>{moment(listOfStatistic.loginTime).format("DD-MM-YYYY")}</span> </p>
                  </div>

                </Col>
                <Col
                  xxl={7}
                  xl={7}
                  lg={7}
                  md={7}
                  sm={24}
                  xs={24}
                >
                  <h2 class="sessTitle">{t("Session")}</h2>
                  <div style={{ position: "absolute", width: "210px", height: "210px", marginLeft: "20px", zIndex: 1 }}>
                    <svg
                      width="100%"
                      height="100%"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        pointerEvents: "none",
                      }}
                    >
                      <circle
                        cx="50%"
                        cy="50%"
                        r="45%"
                        stroke="#C3E4DE"
                        strokeWidth="2"
                        fill="transparent"
                      />
                    </svg>

                  </div>
                  <Chart options={chartOptions} series={chartSeries} type="pie" height={550} />
                </Col>
              </Row>
              <hr />
              <Row gutter={25} >
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}

                >
                  <div class="detailsmain">
                    <h2 >{t("Performance Statistics")}</h2>
                    <h2>{t("OVERALL SUCCESS RATE")}</h2>
                    <p>{t("The overall success rate assesses learners' performance by combining their scores on multiple choice questions and interactive scenarios, with weighting adapted to the importance of each activity.")}</p>
                    <div className="d-flex">
                      <div className="chart-container">
                        <Chart options={radialOptions} series={radialSeries} type="radialBar" height={350} />
                      </div>
                      <div className="text-container">
                        <p>{listOfStatistic?.overallSuccessRateMessage}</p>
                      </div>
                    </div>

                  </div>

                </Col>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                >
                  <div class="detailsmain">
                    <h2 >{t("Satisfaction statistics")}</h2>
                    <h2>{t("SATISFACTION RATE")}</h2>
                    <p>{t("The satisfaction rate evaluates learners' feelings about the training, helping to measure its perceived impact and identify areas for improvement, while remaining suitable for use in VR.")}</p>
                    <div className="d-flex">
                      <div className="chart-container">
                        <Chart options={radialOptions} series={radialSeries0} type="radialBar" height={350} />
                      </div>
                      <div className="text-container">
                        <p>{listOfStatistic?.satisfactionRateMessage}</p>
                      </div>
                    </div>
                  </div>

                </Col>
              </Row>
              <hr />
              <h2 className="engTitle">{t("Engagement Statistics")}</h2>
              <Row gutter={25} >
                <Col
                  xxl={9}
                  xl={9}
                  lg={9}
                  md={9}
                  sm={24}
                  xs={24}

                >
                  <div class="detailsmain">
                    <h2>{t("CONTENT COMPLETION RATE")}</h2>
                    <p>{t("The completion rate indicates the portion of content followed by a learner, allowing you to check whether they have completed an entire video or module.")}</p>
                  </div>

                </Col>
                <Col
                  xxl={15}
                  xl={15}
                  lg={15}
                  md={15}
                  sm={24}
                  xs={24}

                >
                  <div className="d-flex">
                    <div className="chart-container">
                      <Chart options={radialOptions} series={radialSeries1} type="radialBar" height={350} />
                    </div>
                    <div className="text-container">
                      <p>{listOfStatistic?.engagementStatistics?.contentCompletionRateMessage}</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={25} >
                <Col
                  xxl={9}
                  xl={9}
                  lg={9}
                  md={9}
                  sm={24}
                  xs={24}

                >
                  <div class="detailsmain">
                    <h2>{t("COMPREHENSION RATE")}</h2>
                    <p>{t("The comprehension rate evaluates the effort to assimilate the content by analyzing the segments reviewed, the pauses and the errors in the MCQs or interactive scenarios.")}</p>
                  </div>

                </Col>
                <Col
                  xxl={15}
                  xl={15}
                  lg={15}
                  md={15}
                  sm={24}
                  xs={24}

                >
                  <div className="d-flex">
                    <div className="chart-container">
                      <Chart options={radialOptions} series={radialSeries2} type="radialBar" height={350} />
                    </div>
                    <div className="text-container">
                      <p>{listOfStatistic?.engagementStatistics?.comprehensionRateMessage}</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={25} >
                <Col
                  xxl={9}
                  xl={9}
                  lg={9}
                  md={9}
                  sm={24}
                  xs={24}

                >
                  <div class="detailsmain">
                    <h2>{t("CONCENTRATION RATE")}</h2>
                    <p>{t("The concentration rate assesses engagement and the attention of the learner by analyzing the time spent and progress in the module, reflecting its involvement without being limited to results.")}</p>
                  </div>

                </Col>
                <Col
                  xxl={15}
                  xl={15}
                  lg={15}
                  md={15}
                  sm={24}
                  xs={24}

                >
                  <div className="d-flex">
                    <div className="chart-container">
                      <Chart options={radialOptions} series={radialSeries3} type="radialBar" height={350} />
                    </div>
                    <div className="text-container">
                      <p>{listOfStatistic?.engagementStatistics?.concentrationRateMessage}</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={25} >
                <Col
                  xxl={9}
                  xl={9}
                  lg={9}
                  md={9}
                  sm={24}
                  xs={24}

                >
                  <div class="detailsmain">
                    <h2>{t("OVERALL PERFORMANCE INDICATOR")}</h2>
                    <p>{t("The overall performance indicator combines the main indicators to provide a summary view of learners’ efforts and performance.")}</p>
                  </div>

                </Col>
                <Col
                  xxl={15}
                  xl={15}
                  lg={15}
                  md={15}
                  sm={24}
                  xs={24}

                >
                  <div className="d-flex">
                    <div className="chart-container">
                      <Chart options={radialOptions} series={radialSeries4} type="radialBar" height={350} />
                    </div>
                    <div className="text-container">
                      <p>{listOfStatistic?.engagementStatistics?.globalPerformanceIndicatorMessage}</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={25} >
                <Col
                  xxl={9}
                  xl={9}
                  lg={9}
                  md={9}
                  sm={24}
                  xs={24}

                >
                  <div class="detailsmain">
                    <h2>{t("MODULE DIFFICULTY RATE")}</h2>
                    <p>{t("The difficulty rate measures the perceived complexity of a module by analyzing viewing repetitions and multiple choice errors.")}</p>
                  </div>

                </Col>
                <Col
                  xxl={15}
                  xl={15}
                  lg={15}
                  md={15}
                  sm={24}
                  xs={24}

                >
                  <div className="d-flex">
                    <div className="chart-container">
                      <Chart options={radialOptions} series={radialSeries5} type="radialBar" height={350} />
                    </div>
                    <div className="text-container">
                      <p>{listOfStatistic?.engagementStatistics?.moduleDifficultyRateMessage}</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <h2 className="engTitle">{t("Detailed statistics per module")}</h2>
              {listOfStatistic?.moduleStatistics?.length > 0 ? (
                listOfStatistic.moduleStatistics.map((val, vIndex) => {
                  const dynamicBarOptions = {
                    series: [
                      {
                        name: val.courseName, // Dynamic course name
                        data: [
                          { x: t("Overall success rate"), y: val.overallSuccessRate || 0 },
                          { x: t("Content completion rate"), y: val.completionRate || 0 },
                          { x: t("Comprehension rate"), y: val.understandingRate || 0 },
                          { x: t("Concentration rate"), y: val.concentrationRate || 0 },
                          { x: t("Overall performance indicator"), y: val.globalPerformanceIndicator || 0 },
                          { x: t("Module difficulty rate"), y: val.difficultyRate || 0 },
                        ],
                      },
                    ],
                    chart: { type: "bar", height: 280 },
                    plotOptions: {
                      bar: {
                        borderRadius: 20,
                        borderRadiusApplication: "end",
                        dataLabels: { position: "top" },
                      },
                    },
                    colors: ["#06a689"],
                    xaxis: {
                      type: "category",
                      categories: [
                        t("Overall success rate"),
                        t("Content completion rate"),
                        t("Comprehension rate"),
                        t("Concentration rate"),
                        t("Overall performance indicator"),
                        t("Module difficulty rate"),
                      ],
                      labels: { style: { fontSize: "10px", fontWeight: 700 } },
                    },
                    yaxis: {
                      min: 0,
                      max: 100,
                      tickAmount: 5,
                      labels: { formatter: (value) => `${value}%` },
                    },
                    tooltip: { x: { formatter: (val) => `${val} Sales` } },
                  };

                  return (
                    <div key={vIndex} className="detailsmainn">
                      <h2>
                        {t("Module")} {vIndex + 1}: <span>{val.courseName}</span>
                      </h2>
                      <div id="chart">
                        <Chart options={dynamicBarOptions} series={dynamicBarOptions.series} type="bar" height={280} />
                      </div>
                      <div className="table-container">
                        <table className="question-table">
                          <thead>
                            <tr>
                              <th>{t("Questions")}</th>
                              <th>{t("Correct answer")}</th>
                              <th>{t("Apparent response")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {val?.questions.length > 0 ? (
                              val?.questions.map((question, qIndex) => {
                                const optionLabels = ["A", "B", "C", "D"];
                                return (
                                  <tr key={qIndex}>
                                    <td className="correct-answer">
                                      {qIndex + 1}. {question?.question} &nbsp;&nbsp;&nbsp;
                                      {question?.options.map((option, oIndex) => (
                                        <span key={oIndex}>
                                          {optionLabels[oIndex]}. {option.option}{" "}&nbsp;&nbsp;&nbsp;
                                          {oIndex < question.options.length - 1 ? "/" : ""}&nbsp;&nbsp;&nbsp;
                                        </span>
                                      ))}
                                    </td>
                                    <td className="correct-answer">
                                      {question?.options[question.correctAnswer - 1]?.option}
                                    </td>
                                    <td className="apparent-answer">
                                      {question?.userAnswer}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={3} className="no-data">No Data Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                      </div>
                      <hr />
                    </div>
                  );
                })
              ) : (
                <p>{t("No statistics available")}</p>
              )}


            </div>
          </div>
        </section>
      )}

    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default AdminCompanyWorkSpace;
