import { Form, Input, Modal, Pagination, Tabs } from "antd";
import AOS from "aos";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";

// DEFINE MAIN FUNCTION
const TrainerStactistics = () => {
  // DEFINE ALL STATES
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [registerModal, setRegisterModal] = useState(false);
  const [registerModalType, setRegisterModalType] = useState("");

  // DEFINE USEEFFECT
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const memberData = [
    {
      identity: {
        email: "Anrika.keninonen@vegos.com",
        name: "Anrika keninonen",
        premium: false,
      },
      percentage: 100,
      duration: "35h",
      role: "100",
    },
    {
      identity: {
        email: "creator@vegosindustries.com",
        name: "Anrika Powell",
        premium: true,
      },
      percentage: 100,
      duration: "35h",
      role: "91",
    },
    {
      identity: {
        email: "learner@vegos.com",
        name: "Anrika Powell",
        premium: true,
      },
      percentage: 90,
      duration: "31h",
      role: "88",
    },
    {
      identity: {
        email: "annis.outlaw@vegos.com",
        name: "Annis Outlaw",
        premium: false,
      },
      percentage: 90,
      duration: "31h",
      role: "",
    },
    {
      identity: {
        email: "aundry.heare@vegos.com",
        name: "Aundry Heare",
        premium: false,
      },
      percentage: 90,
      duration: "31h",
      role: "",
    },
    {
      identity: {
        email: "aurelie.truch@gmail.com",
        name: "Aurelie Truch",
        premium: true,
      },
      percentage: 90,
      duration: "31h",
      role: "",
    },
    {
      identity: {
        email: "trainer@vegos.com",
        name: "Aurore Campean",
        premium: true,
      },
      percentage: 100,
      duration: "35h",
      role: "",
    },
  ];

  // REGISTER FORM SUBMIT FUNCTION DEFINE
  const onFinish = async (values) => {
    setRegisterModal(false);
    form.resetFields();
    setRegisterModalType("");
  };

  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <Modal
        title={`${registerModalType == "edit" ? t("Edit") : t("Create")
          } ${"a registration link"}`}
        centered
        open={registerModal}
        okText={`${registerModalType == "edit" ? t("Edit") : t("Create")} ${t(
          "link"
        )}`}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setRegisterModal(false);
          form.resetFields();
          setRegisterModalType("");
        }}
      >
        <div className={styles.registerModalData}>
          <div className={styles.image}>
            {" "}
            <label>
              <FeatherIcon icon="box" color="white" size={36} />
            </label>
          </div>
          <div className={styles.details}>
            <label>Vegos Softskills</label>
            <label>7 Experiences</label>
          </div>
        </div>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            label={t("Link Name")}
            name="linkName"
            rules={[
              {
                required: true,
                message: t("Please enter your link name!"),
              },
            ]}
          >
            <Input
              placeholder={t("enter your link name")}
              style={{ height: "45px" }}
            />
          </Form.Item>
          <Form.Item label={t("Welcome text")} name="welcomeText">
            <Input
              placeholder={t("enter your welcome text")}
              style={{ height: "45px" }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <div className={styles.headerComponent} data-aos="fade-right">
        <span>{t("Statistics")}</span> <hr data-aos="fade-right" />
      </div>

      <div className={styles.UserListingComponent} data-aos="fade-right">
        <Tabs
          defaultActiveKey="2"
          items={[
            {
              key: 1,
              label: `Members (114)`,
              children: <MembersTableComponent memberData={memberData} />,
              icon: <FeatherIcon icon="user-check" size={22} />,
            },
          ]}
        />
      </div>
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default TrainerStactistics;

const MembersTableComponent = ({ memberData }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.membersTableComponent}>
        <div className={styles.tableHeader}>
          <span>{t("Identity")}</span>
          <span>{t("Completion Percentage")}</span>
          <span>{t("Total Hours Completed")}</span>
          <span>{t("Accuracy rate")}</span>
        </div>
        {memberData.map((value, index) => {
          return (
            <>
              <div key={index} className={styles.tabelData}>
                <span>
                  <div className={styles.container}>
                    <div className={styles.memberData}>
                      <div className={styles.image}>
                        {`${value.identity.name.split(" ")[0].split("")[0]}${value.identity.name.split(" ")[1].split("")[0]
                          }`}
                      </div>
                      {value.identity.premium ? (
                        <label>
                          {" "}
                          <FeatherIcon
                            icon="star"
                            color="white"
                            fill="white"
                            size={15}
                          />
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={styles.details}>
                      <div className={styles.name}>{value.identity.name}</div>
                      <div className={styles.description}>
                        {value.identity.email}
                      </div>
                    </div>
                  </div>
                </span>
                <span>
                  {value.percentage == 100 ? (
                    <>
                      <div
                        style={{
                          backgroundColor: "white",
                        }}
                        className={styles.moduleDataContent}
                      >
                        <span
                          style={{
                            backgroundColor: "#25AE88",
                          }}
                          className={styles.moduleData}
                        >
                          <label>{`${value.percentage}%`}</label>
                          <label>
                            <label
                              style={{
                                backgroundColor: "#25AE88",
                                width: `${value.percentage}%`,
                              }}
                            ></label>
                          </label>
                        </span>
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        backgroundColor: "#DADDE1",
                      }}
                      className={styles.moduleDataContent}
                    >
                      <span className={styles.moduleMinData}>
                        <label
                          style={{
                            width: `${value.percentage}%`,
                          }}
                        >
                          {`${value.percentage}%`}
                        </label>
                      </span>
                    </div>
                  )}
                </span>
                <span> {value.duration}</span>
                <span>{value.role ? `${value.role}%` : ""}</span>
              </div>
            </>
          );
        })}
        <div className={styles.pagination}>
          <Pagination total={50} showSizeChanger />
        </div>
      </div>
    </>
  );
};
