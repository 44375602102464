import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Empty,
  Pagination,
  Popover,
  Progress,
  Row,
  Skeleton,
} from "antd";
import FeatherIcon from "feather-icons-react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import {
  learningCourseList,
  notLearningCourseList,
} from "../../../../../../redux/user/learner/course/action";
import {
  getItem,
  removeItem,
} from "../../../../../../utility/localStorageControl";

// DEFINE MAIN FUNCTION
const CompanyWorkSpace = () => {
  // DEFINE ALL STATES
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const courseId = location.state?.id;
  const [notLearningPageSize, setNotLearningPageSize] = useState(10);
  const [notLearningCurrentPage, setNotLearningCurrentPage] = useState(1);
  const [notLearningSearchData, setNotLearningSearchData] = useState("");
  const [learningPageSize, setLearningPageSize] = useState(10);
  const [learningCurrentPage, setLearningCurrentPage] = useState(1);
  const [learningSearchData, setLearningSearchData] = useState("");
  const [infromStartLearning, setInfromStartLearning] = useState(false);

  // DEFINE USEEFFECT FOR INFORM START LEARNING
  useEffect(() => {
    if (courseId && getItem("InfromStartLearning")) {
      setInfromStartLearning(true);
      setTimeout(() => {
        removeItem("InfromStartLearning");
        setInfromStartLearning(false);
      }, 10000);
    }
  }, []);

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      notLearningCoursesList(
        notLearningCurrentPage,
        notLearningPageSize,
        notLearningSearchData
      );
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [notLearningSearchData]);

  // DEFINE USEEFFECT WITH SEARCH DATA
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      learningCoursesList(
        learningCurrentPage,
        learningPageSize,
        learningSearchData
      );
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [learningSearchData]);

  // DEFINE ALL LISTS WITH REDUX
  let listOfNotLearningCourses = useSelector(
    (state) => state.learnerCourse.notLearningList
  );

  let listOfLearningCourses = useSelector(
    (state) => state.learnerCourse.learningList
  );

  let countsOfNotLearningCourse = useSelector(
    (state) => state.learnerCourse.notLearningCourseListCounts
  );
  let countsOfLearningCourse = useSelector(
    (state) => state.learnerCourse.learningCourseListCounts
  );

  // DEFINE FUNCTION FOR COURSE LIST
  const notLearningCoursesList = async (page, current, search) => {
    await dispatch(notLearningCourseList(page, current, search));
  };

  // DEFINE FUNCTION FOR COURSE LIST
  const learningCoursesList = async (page, current, search) => {
    await dispatch(learningCourseList(page, current, search));
  };

  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onNotLearningPageChange = async (page, current) => {
    setNotLearningCurrentPage(page);
    setNotLearningPageSize(current);
    notLearningCoursesList(page, current, notLearningSearchData);
  };

  // DEFINE FUNCTION FOR TABLE PAGE CHANGE
  const onLearningPageChange = async (page, current) => {
    setLearningCurrentPage(page);
    setLearningPageSize(current);
    learningCoursesList(page, current, learningSearchData);
  };
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours ? hours : "0"}h : ${minutes ? minutes : "0"}m : ${seconds ? seconds : "0"}s`;
  };
  // DEFINE MAIN FUNCTION RETURN
  return (
    <>
      <div className={styles.filterContent}>
        <Row gutter={12}>
          <Col xxl={9} xl={9} lg={10} md={13} sm={16} xs={18}>
            <div className={styles.searchData}>
              <span>
                <FeatherIcon icon="search" size={24} />
                <input
                  value={learningSearchData}
                  onChange={(event) => {
                    setLearningSearchData(event.target.value);
                  }}
                  placeholder={t("Search for a workspace...")}
                />
                {learningSearchData == "" ? (
                  ""
                ) : (
                  <label style={{ fontSize: "34px" }}>
                    <FeatherIcon
                      onClick={() => {
                        setLearningSearchData("");
                      }}
                      icon="x"
                      size={22}
                    />
                  </label>
                )}
              </span>
            </div>
          </Col>
          {/* <Col xxl={15} xl={15} lg={14} md={11} sm={8} xs={6}>
            <div className={styles.filterData}>
              <div className={styles.filterIcone}>
                {" "}
                <FeatherIcon icon="grid" fill="black" size={20} />
              </div>
              <div className={styles.filterMenu}>
                <FeatherIcon icon="menu" fill="black" size={20} />
              </div>
            </div>
          </Col> */}
        </Row>
      </div>

      <div className={styles.headerComponent}>
        <span>{t("Learning courses")}</span> <hr />
      </div>

      <div className={styles.latestVideoContent}>
        <Row gutter={25} className="criclebox tablespace mb-24 coursesCard">
          {listOfLearningCourses ? (
            listOfLearningCourses.length > 0 ? (
              listOfLearningCourses.map((value, index) => {
                return (
                  <>
                    <Col
                      key={index}
                      xxl={8}
                      xl={8}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={24}
                    >
                      <Popover
                        open={infromStartLearning && value._id == courseId}
                        title={t("Now, Start your course learning")}
                      >
                        <Card
                          onClick={() => {
                            navigate(`/company/workSpace/learned/${value._id}`);
                          }}
                          hoverable
                          cover={
                            <img
                              alt=""
                              src={
                                value.image
                                  ? value.image == ""
                                    ? "https://img-c.udemycdn.com/course/240x135/1362070_b9a1_2.jpg"
                                    : value.image
                                  : "https://img-c.udemycdn.com/course/240x135/1362070_b9a1_2.jpg"
                              }
                            />
                          }
                        >
                          <Meta
                            title={value.title}
                            description={value.discription}
                          />
                          <Progress
                            className={styles.antProgress}
                            percent={value.completedPercentage}
                            size="small"
                            status="active"
                          />
                          <div className={styles.time_card}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-clock"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <polyline points="12 6 12 12 16 14"></polyline>
                            </svg>
                            <span>
                              {" "}
                              {formatTime(Math.round(value.totalTimeSpend))}
                            </span>
                          </div>
                        </Card>
                      </Popover>
                    </Col>
                  </>
                );
              })
            ) : (
              <div className="no-data-component">
                {" "}
                <Empty />
              </div>
            )
          ) : (
            Array.from(Array(6).keys())
              .map((name) => ({ name }))
              .map((item, index) => {
                return (
                  <Col
                    key={index}
                    xxl={8}
                    xl={8}
                    lg={12}
                    md={12}
                    sm={12}
                    className="course-list-skeleton"
                    xs={24}
                  >
                    <Card
                      cover={
                        <Skeleton.Input
                          key={index}
                          active={true}
                          size={"large"}
                        />
                      }
                    >
                      <Meta title={""} description={""} />
                    </Card>
                  </Col>
                );
              })
          )}
        </Row>
        {listOfLearningCourses?.length > 0 ? (
          <div className="tabelsPagenation">
            <Pagination
              total={countsOfLearningCourse.totalCount}
              showSizeChanger
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={learningPageSize}
              defaultCurrent={1}
              current={learningCurrentPage}
              onChange={onLearningPageChange}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      {/* <div className={styles.headerComponent}>
        <span>{t("Not learned courses")}</span> <hr />
      </div> */}
      {/* <div className={styles.filterContent}>
        <Row gutter={12}>
          <Col xxl={9} xl={9} lg={10} md={13} sm={16} xs={18}>
            <div className={styles.searchData}>
              <span>
                <FeatherIcon icon="search" size={24} />
                <input
                  value={notLearningSearchData}
                  onChange={(event) => {
                    setNotLearningSearchData(event.target.value);
                  }}
                  placeholder={t("Search for a workspace...")}
                />
                {notLearningSearchData == "" ? (
                  ""
                ) : (
                  <label style={{ fontSize: "34px" }}>
                    <FeatherIcon
                      onClick={() => {
                        setNotLearningSearchData("");
                      }}
                      icon="x"
                      size={22}
                    />
                  </label>
                )}
              </span>
            </div>
          </Col>
        
        </Row>
      </div> */}
      {/* <div className={styles.latestVideoContent}>
        <Row gutter={25} className="criclebox tablespace mb-24 coursesCard">
          {listOfNotLearningCourses ? (
            listOfNotLearningCourses.length > 0 ? (
              listOfNotLearningCourses.map((value, index) => {
                return (
                  <>
                    <Col
                      key={index}
                      xxl={8}
                      xl={8}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={24}
                    >
                      <Card
                        onClick={() => {
                          navigate(
                            `/company/workSpace/notLearned/${value._id}`
                          );
                        }}
                        hoverable
                        cover={
                          <img
                            alt=""
                            src={
                              value.image
                                ? value.image == ""
                                  ? "https://img-c.udemycdn.com/course/240x135/1362070_b9a1_2.jpg"
                                  : value.image
                                : "https://img-c.udemycdn.com/course/240x135/1362070_b9a1_2.jpg"
                            }
                          />
                        }
                      >
                        <Meta
                          title={value.title}
                          description={value.discription}
                        />
                      </Card>
                    </Col>
                  </>
                );
              })
            ) : (
              <div className="no-data-component">
                {" "}
                <Empty />
              </div>
            )
          ) : (
            Array.from(Array(6).keys())
              .map((name) => ({ name }))
              .map((item, index) => {
                return (
                  <Col
                    key={index}
                    xxl={8}
                    xl={8}
                    lg={12}
                    md={12}
                    sm={12}
                    className="course-list-skeleton"
                    xs={24}
                  >
                    <Card
                      cover={
                        <Skeleton.Input
                          key={index}
                          active={true}
                          size={"large"}
                        />
                      }
                    >
                      <Meta title={""} description={""} />
                    </Card>
                  </Col>
                );
              })
          )}
        </Row>

        {listOfNotLearningCourses?.length > 0 ? (
          <div className="tabelsPagenation">
            <Pagination
              total={countsOfNotLearningCourse.totalCount}
              showSizeChanger
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={notLearningPageSize}
              defaultCurrent={1}
              current={notLearningCurrentPage}
              onChange={onNotLearningPageChange}
            />
          </div>
        ) : (
          ""
        )}
      </div> */}
    </>
  );
};

// DEFINE MAIN FUNCTION EXPORT
export default CompanyWorkSpace;
